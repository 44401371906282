import React from "react";
import { utils, writeFile } from "xlsx";
import { useLocation } from "react-router-dom";
import CustomInput from "../components/formik/CustomInput";
import { ServiceConact } from "../components/reports/ServiceColumn";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import {
  getComission,
  getComissionPerCrane,
  getComissionPerKM,
  getCurrentFortnight,
  getCurrentFortnightName,
  getOrderType,
  getReason,
} from "../models/ReportOperatorSalary";
import CustomSelect from "../components/formik/CustomSelect";

const OperatorSalaryForm = ({ formik, records, operators, status }) => {
  const baseUrl = window.location.origin;
  const estatus = (id_estatus)=>{
      const estados = {
        1: "Sin asignar",
        2: "Asignada",
        3: "Enviada",
        4: "Contacto",
        5: "Llegada",
        6: "Pagado (contado)",
        7: "Entrega",
        8: "Evaluado",
        9: "Pagado (crédito)",
        10: "Cierre",
        11: "Cancelado",
        12: "Factura"
      };
    
      return estados[id_estatus] || "Desconocido";
  }
  const exportToExcel = () => {
    const workbook = utils.book_new();

    records.filter((record) => record?.orders?.length > 0).map((operator) => {
      const sheetName = `${operator.operador.name} ${operator.operador.first_surname} ${operator.operador.second_surname}`.substring(
        0, 30);
      const sheetData = [
        ["Folio", "Descripción del servicio", "Tipo de Servicio", "Fecha", "Total (Sin IVA)", "KM aproximados", "Comisión", "Razón", "Tipo de orden", "Estatus de la orden"],
      ];

      let totalSubtotal = 0;
      let totalComission = 0;
      let totalDistanceSum = 0;
      operator.orders
      .sort(
        (a, b) => new Date(a.fecha_hora_reporte) - new Date(b.fecha_hora_reporte)
      )
      .map(({ Order,totalDistance }) => {
        if (
          new Date(Order.fecha_hora_reporte) >= getCurrentFortnight().start &&
          new Date(Order.fecha_hora_reporte) <= getCurrentFortnight().end
        ) {
          // Fix
           const comission = isNaN(getComission({...Order, totalDistance})) ? 0 : getComission({...Order, totalDistance}); 
          //const comission = 0
          sheetData.push([
            {
              t: "s",
              v: Order.folio.toString().padStart(5, "0"),
              l: { Target: `${baseUrl}/orders/edit/${Order.id}` },
            },
            getOrderType(Order),
            ServiceConact({ row: Order }),
            format(new Date(Order.fecha_hora_reporte), "dd/MMM/yyyy", {
              locale: es,
            }),
            Order.subtotal,
            { t: "n", v: Number(totalDistance).toFixed(2) },
            { t: "n", v: comission.toFixed(2) },
            getReason(Order),
            Order.is_quick_order? 'Rapida' : 'Oficina',
            estatus(Order.current_order_status_id)
          ]);
          totalSubtotal += Order.subtotal;
          totalComission += comission;
          totalDistanceSum += Number(totalDistance);
        }

      });

        sheetData.push([
          { t: "s", v: "" }, // Celda vacía
        ]);

        sheetData.push([
          { t: "s", v: "Total" },
          { t: "s", v: "" },
          { t: "s", v: "" },
          { t: "s", v: "" },
          { t: "n", v: totalSubtotal },
          { t: "n", v: totalDistanceSum.toFixed(2) },
          { t: "s", v: "" },
          { t: "s", v: "" },
          // { t: "n", v: totalComissionCrane.toFixed(2) },
          { t: "n", v: 0 },
          { t: "n", v: totalComission.toFixed(2) },
          { t: "s", v: "" },
        ]);
        const worksheet = utils.aoa_to_sheet(sheetData);
        worksheet["!cols"] = [
          { wch: 20 },
          { wch: 35 },
          { wch: 20 },
          { wch: 30 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 35 },
          { wch: 20 },
          { wch: 20 },
          { wch: 35 },
        ];
        utils.book_append_sheet(workbook, worksheet, sheetName);
      });

    writeFile(workbook, getCurrentFortnightName() + ".xlsx");
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="row">
          <CustomInput
            label="Fecha inicio"
            field="start"
            type="date"
            formik={formik}
            sm={2}
          />
          <CustomInput
            label="Fecha fin"
            field="end"
            type="date"
            formik={formik}
            sm={2}
          />

          <CustomSelect
            label="Operadores"
            field="operator"
            formik={formik}
            options={operators}
            required
            sm={2}
          />

          <CustomSelect
            label="Estatus orden"
            field="status"
            formik={formik}
            options={status}
            required
            sm={2}
          />

          {/*<CustomInput
            label="Operadores"
            field="operator"
            type="text"
            formik={formik}
            sm={2}
            disabled
            unclickable
          />
          <CustomInput
            label="Estatus orden"
            field="status"
            type="text"
            formik={formik}
            sm={2}
            disabled
            unclickable
          />*/}

          <div className="col-md-4">
            <div
              style={{
                display: "flex",
                gap: 10,
                justifyContent: "end",
                marginTop: 20,
              }}
            >
              <button
                className="btn btn-primary btn-lg"
                onClick={() => formik.handleSubmit()}
              >
                <i class="fa-solid fa-magnifying-glass" />
                &nbsp;Buscar
              </button>
              <button
                className="btn btn-success btn-lg"
                onClick={() => exportToExcel()}
              >
                <i class="fa-solid fa-file-excel" />
                &nbsp;Descargar
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OperatorSalaryForm;
