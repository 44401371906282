const Menu = [
  {
    path: '/orders', icon: 'fa-solid fa-envelope-open-text', title: 'Cartas de servicio'
    //, label: '8'
  },
  {
    path: '/reports', icon: 'fa-solid fa-clipboard-check', title: 'Reportes', admin: true ,
    children: [
      { path: '/reports/operators-salary', title: 'Operadores' },
      { path: '/reports/order-invoice', title: 'Facturas' },
      { path: '/reports/order-insurance', title: 'Aseguradoras' },
      { path: '/reports/income', title: 'Ingresos' },
      { path: '/reports/fuel', title: 'Consumo de combustible' },
      { path: '/reports/salaries', title: 'Auxiliar de nómina' },
      { path: '/reports/prices', title: 'Lista de precios' },
      {
        path: '/reports/crane', title: 'Grúas',
        children: [
          { path: '/reports/crane/maintenance', title: 'Servicios de mantenimiento' },
          { path: '/reports/crane/insurance', title: 'Seguro de grúa' },
          { path: '/reports/crane/permission', title: 'Tenencia y permisos' }
        ]
      },
      {
        path: '/reports/operators', title: 'Operadores',
        children: [
          { path: '/reports/operators/licenses', title: 'Licencias de manejo' },
          { path: '/reports/operators/holidays', title: 'Vacaciones' }
        ]
      }
    ]
  },

  {
    path: '/catalogs', icon: 'fa-solid fa-book', title: 'Catálogos',
    children: [
      {
        path: '', title: 'Tarifas',
        children: [
          { path: '/catalogs/localRates', title: 'Locales' },
          { path: '/catalogs/foreignRates', title: 'Foráneas' },
        ]
      },
      //{ path: '/catalogs/agreements', title: 'Convenios' },
      {
        path: '', title: 'Aseguradoras',
        children: [
          { path: '/catalogs/insurers', title: 'Listado de aseguradoras' },
          { path: '/catalogs/typeEvidences', title: 'Tipos de Evidencias' },
        ]
      },
      {
        path: '', title: 'Bancos y Cuentas bancarias',
        children: [
          { path: '/catalogs/banks', title: 'Bancos' },
          { path: '/catalogs/bankAccounts', title: 'Cuentas bancarias' },
        ]
      },
      { path: '/catalogs/locations', title: 'Ubicaciones' },
      { path: '/catalogs/brandsModels', title: 'Marcas y Modelos de Vehículos' },
      { path: '/catalogs/shieldingTypes', title: 'Categorías de Blindaje' },
      { path: '/catalogs/cranes', title: 'Grúas' },
      { path: '/catalogs/assistanceVehicles', title: 'Vehículos de asistencia' },
      { path: '/catalogs/liftTrucks', title: 'Montacargas' },
      //{ path: '/catalogs/headquarters', title: 'Bases' },
      //{ path: '/catalogs/customers', title: 'Clientes' },
      //{ path: '/catalogs/drivers', title: 'Operadores' },
      //{ path: '/catalogs/vehycle-types', title: 'Tipos de vehículos' },
      { path: '/catalogs/crane-types', title: 'Categorías de grúas' },
      { path: '/catalogs/colors', title: 'Colores' },
    ]
  },
  {
    path: '/settings', icon: 'fa-solid fa-screwdriver-wrench', title: 'Configuración',
    children: [
      { path: '/settings/users', title: 'Usuarios' },
      { path: '/settings/roles', title: 'Roles y permisos' }
    ]
  },
]

export default Menu;






/**
 
import { useEffect, useState } from "react";
import axiosClient from "./axios.js";

const Menu = () => {
  const [permisos, setPermisos] = useState([]);
  const [filteredMenu, setFilteredMenu] = useState([]);

  // Definir el menú antes de usarloS
  const menuConfig = [
    {
      path: "/orders",
      icon: "fa-solid fa-envelope-open-text",
      title: "Cartas de servicio",
      //, label: '8'
    },
    {
      path: "/reports",
      icon: "fa-solid fa-clipboard-check",
      title: "Reportes",
      children: [
        { path: "/reports/operators-salary", title: "Operadores" },
        { path: "/reports/income", title: "Ingresos" },
        { path: "/reports/fuel", title: "Consumo de combustible" },
        { path: "/reports/salaries", title: "Auxiliar de nómina" },
        { path: "/reports/prices", title: "Lista de precios" },
        {
          path: "/reports/crane",
          title: "Grúas",
          children: [
            {
              path: "/reports/crane/maintenance",
              title: "Servicios de mantenimiento",
            },
            { path: "/reports/crane/insurance", title: "Seguro de grúa" },
            { path: "/reports/crane/permission", title: "Tenencia y permisos" },
          ],
        },
        {
          path: "/reports/operators",
          title: "Operadores",
          children: [
            {
              path: "/reports/operators/licenses",
              title: "Licencias de manejo",
            },
            { path: "/reports/operators/holidays", title: "Vacaciones" },
          ],
        },
      ],
    },
  
    {
      path: "/catalogs",
      icon: "fa-solid fa-book",
      title: "Catálogos",
      children: [
        {
          path: "",
          title: "Tarifas",
          children: [
            { path: "/catalogs/localRates", title: "Locales" },
            { path: "/catalogs/foreignRates", title: "Foráneas" },
          ],
        },
        //{ path: '/catalogs/agreements', title: 'Convenios' },
        {
          path: "",
          title: "Aseguradoras",
          children: [
            { path: "/catalogs/insurers", title: "Listado de aseguradoras" },
            { path: "/catalogs/typeEvidences", title: "Tipos de Evidencias" },
          ],
        },
        {
          path: "",
          title: "Bancos y Cuentas bancarias",
          children: [
            { path: "/catalogs/banks", title: "Bancos" },
            { path: "/catalogs/bankAccounts", title: "Cuentas bancarias" },
          ],
        },
        { path: "/catalogs/locations", title: "Ubicaciones" },
        {
          path: "/catalogs/brandsModels",
          title: "Marcas y Modelos de Vehículos",
        },
        { path: "/catalogs/shieldingTypes", title: "Categorías de Blindaje" },
        { path: "/catalogs/cranes", title: "Grúas" },
        {
          path: "/catalogs/assistanceVehicles",
          title: "Vehículos de asistencia",
        },
        { path: "/catalogs/liftTrucks", title: "Montacargas" },
        //{ path: '/catalogs/headquarters', title: 'Bases' },
        //{ path: '/catalogs/customers', title: 'Clientes' },
        //{ path: '/catalogs/drivers', title: 'Operadores' },
        //{ path: '/catalogs/vehycle-types', title: 'Tipos de vehículos' },
        { path: "/catalogs/crane-types", title: "Categorías de grúas" },
        { path: "/catalogs/colors", title: "Colores" },
      ],
    },
    {
      path: "/settings",
      icon: "fa-solid fa-screwdriver-wrench",
      title: "Configuración",
      children: [
        { path: "/settings/users", title: "Usuarios" },
        { path: "/settings/roles", title: "Roles y permisos" },
      ],
    },
  ];

  // Función para filtrar el menú según los permisos
  const filterMenuByPermissions = (menu, permissions) => {
  
    return menu
      .map((item) => {
        

          const hasPermission = !item.path || permissions.includes(item.title);

          return hasPermission ? item : null;
        })
        .filter(Boolean);
    };
  
    useEffect(() => {
      const fetchPermissions = async () => {
        try {
          const userId = localStorage.getItem("userId");
          const response = await axiosClient.get(`/roles/${userId}`);
          const responsePermissions = await axiosClient.get(`/roles/findPermissions/${response?.data?.id}`);
          console.log("Permisos obtenidos:", responsePermissions);
  
          if (responsePermissions?.data) {
            // Extraer los permisos de cada categoría usando `description`
            const permisosExtraidos = responsePermissions.data.flatMap((item) =>
              item.permissions.map((permiso) => permiso.description)
            );
  
            setPermisos(permisosExtraidos);
          }
        } catch (error) {
          console.error("Error obteniendo los permisos:", error);
        }
      };
  
      fetchPermissions();
    }, []);
  
    useEffect(() => {
      if (permisos.length > 0) {
        console.log("los datos son")
        setFilteredMenu(filterMenuByPermissions(menuConfig, permisos));
      }
    }, [permisos]);
  
    console.log("Menú filtrado:", filteredMenu);
    return filteredMenu;
  };
  
  export default Menu;
  
  
  
  
  
  /**
   let Menu = [
      {
        path: "/orders",
        icon: "fa-solid fa-envelope-open-text",
        title: "Cartas de servicio",
        //, label: '8'
      },
      {
        path: "/reports",
        icon: "fa-solid fa-clipboard-check",
        title: "Reportes",
        children: [
          { path: "/reports/operators-salary", title: "Operadores" },
          { path: "/reports/income", title: "Ingresos" },
          { path: "/reports/fuel", title: "Consumo de combustible" },
          { path: "/reports/salaries", title: "Auxiliar de nómina" },
          { path: "/reports/prices", title: "Lista de precios" },
          {
            path: "/reports/crane",
            title: "Grúas",
            children: [
              {
                path: "/reports/crane/maintenance",
                title: "Servicios de mantenimiento",
              },
              { path: "/reports/crane/insurance", title: "Seguro de grúa" },
              { path: "/reports/crane/permission", title: "Tenencia y permisos" },
            ],
          },
          {
            path: "/reports/operators",
            title: "Operadores",
            children: [
              {
                path: "/reports/operators/licenses",
                title: "Licencias de manejo",
              },
              { path: "/reports/operators/holidays", title: "Vacaciones" },
            ],
          },
        ],
      },
    
      {
        path: "/catalogs",
        icon: "fa-solid fa-book",
        title: "Catálogos",
        children: [
          {
            path: "",
            title: "Tarifas",
            children: [
              { path: "/catalogs/localRates", title: "Locales" },
              { path: "/catalogs/foreignRates", title: "Foráneas" },
            ],
          },
          //{ path: '/catalogs/agreements', title: 'Convenios' },
          {
            path: "",
            title: "Aseguradoras",
            children: [
              { path: "/catalogs/insurers", title: "Listado de aseguradoras" },
              { path: "/catalogs/typeEvidences", title: "Tipos de Evidencias" },
            ],
          },
          {
            path: "",
            title: "Bancos y Cuentas bancarias",
            children: [
              { path: "/catalogs/banks", title: "Bancos" },
              { path: "/catalogs/bankAccounts", title: "Cuentas bancarias" },
            ],
          },
          { path: "/catalogs/locations", title: "Ubicaciones" },
          {
            path: "/catalogs/brandsModels",
            title: "Marcas y Modelos de Vehículos",
          },
          { path: "/catalogs/shieldingTypes", title: "Categorías de Blindaje" },
          { path: "/catalogs/cranes", title: "Grúas" },
          {
            path: "/catalogs/assistanceVehicles",
            title: "Vehículos de asistencia",
          },
          { path: "/catalogs/liftTrucks", title: "Montacargas" },
          //{ path: '/catalogs/headquarters', title: 'Bases' },
          //{ path: '/catalogs/customers', title: 'Clientes' },
          //{ path: '/catalogs/drivers', title: 'Operadores' },
          //{ path: '/catalogs/vehycle-types', title: 'Tipos de vehículos' },
          { path: "/catalogs/crane-types", title: "Categorías de grúas" },
          { path: "/catalogs/colors", title: "Colores" },
        ],
      },
      {
        path: "/settings",
        icon: "fa-solid fa-screwdriver-wrench",
        title: "Configuración",
        children: [
          { path: "/settings/users", title: "Usuarios" },
          { path: "/settings/roles", title: "Roles y permisos" },
        ],
      },
    ];
 */