import React, { useEffect, useState } from 'react';
import axiosClient from '../../../../config/axios.js';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import { useAuth } from '../../../../context/AuthContext.js';

function Users() {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortField, setSortField] = useState('desactivation_date'); // Campo de ordenamiento predeterminado
	const [sortDirection, setSortDirection] = useState('desc'); // Sentido de orden predeterminado
	const [firstRender, setFirstRender] = useState(true);

	const deleteUser = id => {
		Swal.fire({
			title: '¿Está seguro que desea borrar al usuario seleccionado?',
			text: "Una vez que se haya borrado el usuario, no podrá recuperarlo.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sí, eliminar usuario',
			cancelButtonText: 'Cancelar',
		}).then((result) => {
			if (result.isConfirmed) {
				axiosClient.delete(`/users/${id}`).then(response => {

					fetchData();
					Swal.fire(
						'Usuario borrado',
						'El usuario se ha borrado exitosamente',
						'success'
					)
				})

			}
		})
	}

	const fetchData = async (currentPage = 1, search = '', sortField, sortDirection) => {
		try {
			const response = await axiosClient.get(`/users/datatable`, {

				params: {
					start: (currentPage - 1) * rowsPerPage,
					length: rowsPerPage,
					search: search,
					sort: firstRender ? "desactivation_date" : sortField,
					dir: firstRender ? "desc" : sortDirection,
				},
			});
			const updatedData = response.data.data.map(user => {
				const allRoleNames = user.Roles.map(role => role.role_name);
				return {
					...user,
					all_role_names: allRoleNames
				};
			});
			response.data.data = updatedData
			setData(response.data);
			console.log("response.data", response.data);
			setLoading(false);
		} catch (error) {
			console.error(error);
		}finally{
			setFirstRender(false);
		}
	};

	useEffect(() => {

		fetchData();
	}, []);

	useEffect(() => {
		setLoading(true);
		fetchData(currentPage, searchValue, sortField, sortDirection);
	}, [currentPage, rowsPerPage, sortField, sortDirection]);

	const columns = [
		{ name: 'ID', selector: row => row.id, sortable: true, sortField: 'id', width: '5%' },
		{
			name: 'Estatus', selector: row => row.desactivation_date, sortable: true, sortField: 'desactivation_date',
			cell: row => {
				if (row.desactivation_date === null) {
					return <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa fa-circle fs-9px fa-fw me-5px"></i> Habilitado</span>;
				} else {
					return <span className="badge border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa fa-circle fs-9px fa-fw me-5px"></i> Deshabilitado</span>;
				}
			}, width: '15%'
		},
		{ name: 'Usuario', selector: row => row.username, sortable: true, sortField: 'username', width: '13%' },
		{ name: 'Nombre', selector: row => row.name, sortable: true, sortField: 'name', width: '15%' },
		{ name: 'Primer apellido', selector: row => row.first_surname, sortable: true, sortField: 'first_surname', width: '15%' },
		{ name: 'Segundo apellido', selector: row => row.second_surname, sortable: true, sortField: 'second_surname', width: '15%' },
		{
			name: 'Roles', button: true,
			cell: row => {
				return <div>
					{row.all_role_names.map(role_name => {
						return <div className='mb-1'><span className="badge me-1 bg-green mb-3">{role_name}</span></div>
					})}

				</div>
			}, width: '10%'
		},
		{
			name: 'Editar', button: true,
			cell: row => <Link to={`/settings/users/edit/${row.id}`}><i className='fa fa-pencil' /></Link>,
			width: '5%'
		},
		{
			name: 'Eliminar', button: true,
			cell: row => <a href="#" onClick={() => deleteUser(row.id)}><i className='fa fa-trash' /></a>,
			width: '5%'
		}
	];

	const customStyles = {
		headCells: {
			style: {
				color: '#202124',
				fontSize: '14px',
				backgroundColor: '#EEEEEE',
				border: 'none'
			},
		}
	};

	const paginationComponentOptions = {
		rowsPerPageText: 'Filas por página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: false,
		selectAllRowsItemText: 'Todos',
	};

	const progressComponent = "<i className='fa fa-trash'></i>";

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			setLoading(true);
			setCurrentPage(1);
			fetchData(currentPage, searchValue, sortField, sortDirection);
		}
	};

	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
						<li className="breadcrumb-item">Configuración</li>
						<li className="breadcrumb-item active">Usuarios</li>
					</ol>
					<h1 className="page-header mb-0">Usuarios</h1>
				</div>
				<div className="ms-auto">
					<Link to={'/settings/users/newUser'} className="btn btn-success btn-rounded px-4 rounded-pill"><i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Nuevo Usuario</Link>
				</div>
			</div>



			<div className="row mb-3">
				<div className="col-xl-12">
					<Panel>
						<PanelHeader>Listado de Usuarios</PanelHeader>
						<PanelBody>
							<DataTable
								title=""
								className="table"
								columns={columns}
								data={data.data}
								progressPending={loading}
								sortServer
								pagination
								paginationServer
								paginationPerPage={rowsPerPage}
								paginationTotalRows={data.recordsTotal} // Esto es solo un valor estimado
								paginationDefaultPage={currentPage}
								subHeader
								customStyles={customStyles}
								highlightOnHover
								pointerOnHover
								paginationComponentOptions={paginationComponentOptions}
								noDataComponent="Sin información por mostrar"
								progressComponent={
									<Spinner animation="border" role="status">
										<span className="visually-hidden">Loading...</span>
									</Spinner>
								}
								subHeaderComponent={
									<div className='row'>
										<div className='col-sm-12 col-md-12'>
											<div className="input-group mb-3">
												<input
													type="text"
													className="form-control form-control-sm"
													placeholder="Buscar..."
													value={searchValue}
													onChange={e => setSearchValue(e.target.value)}
												/>
												<div className="input-group-text btn btn-info"
													onClick={() => {
														setLoading(true);
														setCurrentPage(1);
														fetchData(currentPage, searchValue, sortField, sortDirection); // Realizar búsqueda aquí
													}}><i className="fa fa-search"></i></div>
											</div>
										</div>
									</div>
								}
								onChangePage={(page) => {
									setCurrentPage(page);
								}}
								onSearch={(value) => {
									setSearchValue(value);
									setLoading(true);
									setCurrentPage(1); // Resetea la página al hacer una búsqueda
								}}
								onChangeRowsPerPage={(newPerPage, page) => {
									setRowsPerPage(newPerPage);
									setCurrentPage(1); // Resetea la página al cambiar la cantidad de registros por página
								}}
								onSort={(column, sortDirection) => {
									setSortDirection(sortDirection);
									setSortField(column.sortField);
									setLoading(true);
									setCurrentPage(1); // Resetea la página al hacer una búsqueda
								}}
							/>
						</PanelBody>
					</Panel>
				</div>

			</div>


		</div>

	);
}

export default Users;