import React, { useRef } from 'react';

import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import DateTime from 'react-datetime';
import moment from 'moment';


function OrderStatusInvoice({ fetchCurrentOrderStatusByOrder, setCurrentOrderStatus, modalInstance, reloadOrderDataFromDB, need_invoice }) {
    const { id } = useParams();
    const { Formik } = formik;

    const fechaHoraEnvio = useRef(null);
    console.log('los datos que entro es ')
    return (
        <>
        {need_invoice ?
        
            <Formik
                validationSchema={yup.object().shape({
                    invoice_generation_date: yup.date().required("La fecha y hora de envío es requerida."),
                    invoice_folio: yup.string()
                })}
                initialValues={{
                    invoice_generation_date: new Date(),
                    invoice_folio: ''
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    console.log('los datos de value es ', values)
                    axiosClient.put(`/orders/updateCurrentOrderStatus/${id}`, {
                        order_status_code: 'FACTURA',
                        invoice_generation_date: moment(values.invoice_generation_date).format(),
                        invoice_folio: values.invoice_folio
                    }).then(async response => {
                        const result = await fetchCurrentOrderStatusByOrder();
                        setCurrentOrderStatus(result);
                        console.table("setCurrentOrderStatus", result);

                        if (modalInstance) {
                            modalInstance.hide();
                        }
                        reloadOrderDataFromDB();

                    }).catch(err => {
                        console.log(err.response);
                        Swal.fire({
                            title: 'Ups!',
                            text: 'Ha ocurrido un error',
                            icon: 'error',
                            confirmButtonText: 'Cerrar',
                            cancelButtonText: 'Cancelar',
                            showCancelButton: false,
                        });

                    });
                }}

            >

                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (
                    <>
                        <div className="modal-header">
                            <h4 className="modal-title">Detalle de la factura</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <div className="modal-body">
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-1">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Folio de la factura</Form.Label>
                                        <Form.Control
											type="text"
											name="invoice_folio"
											placeholder="Número de folio"
											value={values?.invoice_folio}
											onChange={handleChange}
											onBlur={handleBlur}
											isInvalid={!!errors.invoice_folio}
											autoComplete="off"
											style={{ flexGrow: 1 }}
										/>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.invoice_folio}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-1">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Fecha de generación</Form.Label>
                                        <DateTime
                                            name="invoice_generation_date"
                                            ref={fechaHoraEnvio}
                                            inputProps={{ placeholder: 'Fecha y hora de envío', readOnly: true }}
                                            closeOnSelect={false}
                                            locale="es"
                                            value={values.invoice_generation_date}
                                            className={errors.invoice_generation_date ? 'is-invalid' : ''}
                                            dateFormat="dddd DD/MMMM/YYYY"
                                            timeFormat="hh:mm A"
                                            initialViewMode="days"
                                            onOpen={(e) => {
                                                fechaHoraEnvio.current.navigate('days');
                                                setFieldTouched('invoice_generation_date', true);
                                            }}
                                            onChange={(e) => {
                                                setFieldValue("invoice_generation_date", e ? e : '');
                                            }}
                                            isInvalid={touched.invoice_generation_date && !!errors.invoice_generation_date}
                                        // updateOnView="time"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.invoice_generation_date}
                                        </Form.Control.Feedback>


                                    </Form.Group>
                                </Row>


                               

                            </Form>

                        </div>
                        <div className="modal-footer">
                            <Button type="button" data-bs-dismiss="modal" className='btn-white'>Cancelar</Button>
                            <Button type="button" onClick={handleSubmit} disabled={errors.operadores}>Guardar</Button>
                        </div>
                    </>
                )}
            </Formik>
            :
            <div>
                {/*<div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                </div>*/}
                <h5 style={{height:'100px', textAlign:'center', marginTop:'10%'}}>Esta orden no requiere factura</h5>
                <div className="modal-footer">
                    <Button type="button" data-bs-dismiss="modal" className='btn-danger'>Cerrar</Button>
                </div>
            </div>
            }

        </>
    );
}

export default OrderStatusInvoice;