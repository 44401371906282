
import * as yup from 'yup';

export const opcionesEsCita = [
    { value: false, label: 'ATENCIÓN INMEDIATA' },
    { value: true, label: 'PROGRAMAR CITA' },
];

export const orderValidationSchema = yup.object().shape({
    es_cita: yup.object(),
    fecha_hora_cita: yup.date().when('es_cita', ([es_cita], schema) => {
        //return (es_cita.value ? schema.required('La fecha y hora de la cita es requerida').min(new Date(), 'La fecha de la cita debe ser igual o posterior a la fecha actual') : schema);
        return schema;
    }),
    fecha_hora_alerta_cita: yup.date(),
    alerta_antes_hora_cita: yup.date(),
    expediente: yup.string(),
    reporte: yup.string(),
    fecha_hora_contacto: yup.date(),
    fecha_hora_entrega: yup.date(),

    //información del servicio
    grua: yup.boolean(),
    grua_vial: yup.boolean().when('grua', ([grua], schema) => {
        return (grua === true ? schema.required('¿El servicio es Vial o Siniestro?') : schema.nullable());
    }),

    auxilio_vial: yup.boolean(),
    montacarga: yup.boolean(),
    corresponsalia: yup.boolean(),
    taxi: yup.boolean(),
    tipo_servicio_observaciones: yup.string('Campo requerido').max(1000, 'El texto no puede tener más de 1,000 caracteres').required("Campo requerido"),

    //Datos de los bienes
    carta_porte: yup.boolean(),
    vehiculos: yup.array().of(
        yup.object().shape({
            vehicle_type_id: yup.object().required("Selecciona o registra un tipo de vehículo"),
            brand_id: yup.object().required("Selecciona o registra una marca de vehículo"),
            vehicle_model_id: yup.object().required("Selecciona o registra un modelo de vehículo"),
            anio: yup.object().when('$carta_porte', {
                is: true,
                then: (schema) => schema.required('El año es obligatorio si la Carta Porte es requerida'),
                otherwise: (schema) => schema.nullable()
            }),
            color_id: yup.object().when('$carta_porte', {
                is: true,
                then: (schema) => schema.required('El color es obligatorio si la Carta Porte es requerida'),
                otherwise: (schema) => schema.nullable()
            }),
            observaciones: yup.string().max(500, 'El texto no puede tener más de 500 caracteres'),
            placas: yup.string().when('$carta_porte', {
                is: true,
                then: (schema) => schema.required('El número de placa es obligatorio si la Carta Porte es requerida'),
                otherwise: (schema) => schema
            }),
            numero_serie: yup.string().when('$carta_porte', {
                is: true,
                then: (schema) => schema.required('Este campo es obligatorio si la Carta Porte es requerida'),
                otherwise: (schema) => schema
            }),
            shielding_type_id: yup.object(),
            carga_kg: yup.number(),
            registrar_volumen: yup.boolean(),
            volumen_largo: yup.number().when('registrar_volumen', {
                is: true,
                then: (schema) => schema.typeError("El largo debe ser un número").required('Campo requerido').positive('Debe ser un número positivo'),
                otherwise: (schema) => schema
            }),
            volumen_ancho: yup.number().when('registrar_volumen', {
                is: true,
                then: (schema) => schema.typeError("El ancho debe ser un número").required('Campo requerido').positive('Debe ser un número positivo'),
                otherwise: (schema) => schema
            }),
            volumen_altura: yup.number().when('registrar_volumen', {
                is: true,
                then: (schema) => schema.typeError("La altura debe ser un número").required('Campo requerido').positive('Debe ser un número positivo'),
                otherwise: (schema) => schema
            }),
            volumen_m3: yup.number().when('registrar_volumen', {
                is: true,
                then: (schema) => schema.typeError("El volumen debe ser un número").required('Favor de ingresar las medidas').positive('Debe ser un número positivo'),
                otherwise: (schema) => schema
            }),
            estado_llantas_giran: yup.boolean().nullable(),
            estado_volante_gira: yup.boolean().nullable(),
            estado_pie_calle: yup.boolean().nullable(),
            estado_neutral: yup.boolean().nullable(),
            estado_en_cajon: yup.boolean().nullable(),
            asistencia_paso_corriente: yup.boolean().nullable(),
            asistencia_combustible: yup.boolean().nullable(),
            asistencia_cambio_neumatico: yup.boolean().nullable(),
            asistencia_cerrajeria: yup.boolean().nullable(),
        })
    ),
    bienes_observaciones: yup.string().max(1000, 'El texto no puede tener más de 1,000 caracteres'),

    //Datos de contacto y seguimiento
    client_type_id: yup.object().required('Selecciona el tipo de cliente'),
    insurance_id: yup.object().when('client_type_id', ([client_type_id], schema) => {
        return client_type_id && client_type_id.client_type_code === 'ASEGURADORA' ? schema.required('Selecciona la aseguradora') : schema;
    }),
    autorizacion_cabina: yup.string(),
    empresa: yup.string(),
    cliente_vip: yup.boolean(),
    beneficiario_servicio: yup.string(),
    telefono_principal: yup.string(),
    numero_poliza: yup.string(),
    numero_siniestro: yup.string(),
    numero_folio: yup.string(),
    tipo_pago_id: yup.object().when('es_cita', ([es_cita], schema) => {
        return es_cita.value ? schema.required('Selecciona el tipo de pago') : schema.required('Selecciona el tipo de pago');
    }),

    monto_aseguradora_paga: yup.number().when(['tipo_pago_id', 'client_type_id', 'total'], ([tipo_pago_id, client_type_id, total], schema) => {
        return (client_type_id?.client_type_code !== 'PARTICULAR' && tipo_pago_id?.code === 'CONTADO/CREDITO') ? schema.required('Ingresa un monto').max(total, 'El monto que paga la aseguradora debe ser menor al Total de la Orden') : schema.nullable();
    }),


    excedente_asegurado: yup.number().when(['tipo_pago_id', 'client_type_id', 'total', 'monto_aseguradora_paga'], ([tipo_pago_id, client_type_id, total, monto_aseguradora_paga], schema) => {
        return (client_type_id?.client_type_code !== 'PARTICULAR' && tipo_pago_id?.code === 'CONTADO/CREDITO') ? schema.required('Ingresa un monto').max(total, 'El monto Excecente Asegurado debe ser menor al Total de la Orden').test({
            name: 'sumaTotal',
            exclusive: true,
            message: 'La suma del monto a pagar por el Asegurado y Excedente debe ser igual al Total',
            test: function (value) {
                const sumaTotal = monto_aseguradora_paga + value;
                return sumaTotal === total;
            },
        }) : schema.nullable();
    }),
    datos_contacto_seguimiento_observaciones: yup.string().max(1000, 'El texto no puede tener más de 1,000 caracteres'),

    //Ubicación de la atención
    service_location_type_id: yup.object(),
    order_locations: yup.array().of(
        yup.object().shape({
            //index: yup.number().required("Se requiere el orden de la ubicación"),
            cuadro_busqueda: yup.string(),
            //direccion: yup.string().required("Se requiere agregar alguna dirección igual o similar a la real"),
            referencia: yup.string(),
            latitude: yup.string().required("Se requiere la latitud"),
            longitude: yup.string().required("Se requiere la longitud"),
        })
    ),
    registrar_ubicacion_origen: yup.boolean(),
    registrar_ubicacion_destino: yup.boolean(),
    nombre_ubicacion_origen: yup.string(),
    nombre_ubicacion_destino: yup.string(),
    distancia_ubicacion_origen: yup.number(),
    distancia_ubicacion_destino: yup.number(),

    //Cálculo de montos
    ubicacion_tarifaria_origen_id: yup.object(),
    ubicacion_tarifaria_destino_id: yup.object(),
    km: yup.number().when(['service_location_type_id', 'manejar_tarifas_establecidas'], ([service_location_type_id, manejar_tarifas_establecidas], schema) => {
        return (service_location_type_id?.service_location_code === 'FORANEO' && manejar_tarifas_establecidas === false ? schema.required('Se requiere la distancia en kilómetros').positive("La distancia debe ser positiva") : schema.nullable());
    }),


    crane_id: yup.object().when(['grua', 'montacarga', 'corresponsalia'], ([grua, montacarga, corresponsalia], schema) => {
        return ((grua === true || montacarga === true || corresponsalia === true) ? schema.required('Selecciona una grúa') : schema.nullable());
    }),

    montacargas: yup.array(),
    operadores: yup.array().of(
        yup.object().shape({

        })
    )
        .test(
            'encargado-required',
            'Al haber seleccionado 2 o más operadores, debe haber un operador encargado',
            function (value) {
                if (value) {
                    if (value.length <= 1) {
                        return true;
                    }
                    const encargados = [...value.filter(operador => operador.encargado)];

                    return encargados.length === 1;
                } else {
                    return true;
                }

            }
        )
        .nullable(),
    base_id: yup.object(),


    grua_subtotal: yup.number().when('grua', ([grua], schema) => {
        return (grua === true ? schema.required('El subtotal debe ser mayor o igual a 0').min(0, 'El subtotal debe ser mayor o igual a 0') : schema.nullable());
    }),
    grua_tarifa_kilometro: yup.number().when(['grua', 'service_location_type_id', 'manejar_tarifas_establecidas'], ([grua, service_location_type_id, manejar_tarifas_establecidas], schema) => {
        return ((grua === true && service_location_type_id?.service_location_code === 'FORANEO' && manejar_tarifas_establecidas === false) ? schema.required('La tarifa por kilómetro debe ser mayor o igual a 0').min(0, 'La tarifa por kilómetro debe ser mayor o igual a 0') : schema.nullable());
    }),
    grua_banderazo: yup.number().when(['grua', 'service_location_type_id', 'manejar_tarifas_establecidas'], ([grua, service_location_type_id, manejar_tarifas_establecidas], schema) => {
        return ((grua === true && service_location_type_id?.service_location_code === 'FORANEO' && manejar_tarifas_establecidas === false) ? schema.required('El banderazo debe ser mayor o igual a 0').min(0, 'El banderazo debe ser mayor o igual a 0') : schema.nullable());
    }),
    grua_casetas: yup.number().when(['grua', 'service_location_type_id', 'manejar_tarifas_establecidas'], ([grua, service_location_type_id, manejar_tarifas_establecidas], schema) => {
        return ((grua === true && service_location_type_id?.service_location_code === 'FORANEO' && manejar_tarifas_establecidas === false) ? schema.required('El monto de casetas debe ser mayor o igual a 0').min(0, 'El monto de casetas debe ser mayor o igual a 0') : schema.nullable());
    }),


    aux_vial_tarifa_kilometro: yup.number().when(['auxilio_vial', 'service_location_type_id', 'manejar_tarifas_establecidas'], ([auxilio_vial, service_location_type_id, manejar_tarifas_establecidas], schema) => {
        return (auxilio_vial === true && service_location_type_id?.service_location_code === 'FORANEO' && manejar_tarifas_establecidas === false ? schema.required('La tarifa por kilómetro debe ser mayor o igual a 0').min(0, 'La tarifa por kilómetro debe ser mayor o igual a 0') : schema.nullable());
    }),
    aux_vial_subtotal: yup.number().when('auxilio_vial', ([auxilio_vial], schema) => {
        return (auxilio_vial === true ? schema.required('El subtotal debe ser mayor o igual a 0').min(0, 'El subtotal debe ser mayor o igual a 0') : schema.nullable());
    }),
    aux_vial_banderazo: yup.number().when(['auxilio_vial', 'service_location_type_id', 'manejar_tarifas_establecidas'], ([auxilio_vial, service_location_type_id, manejar_tarifas_establecidas], schema) => {
        return (auxilio_vial === true && service_location_type_id?.service_location_code === 'FORANEO' && manejar_tarifas_establecidas === false ? schema.required('El banderazo debe ser mayor o igual a 0').min(0, 'El banderazo debe ser mayor o igual a 0') : schema.nullable());
    }),
    aux_vial_casetas: yup.number().when(['auxilio_vial', 'service_location_type_id', 'manejar_tarifas_establecidas'], ([auxilio_vial, service_location_type_id, manejar_tarifas_establecidas], schema) => {
        return (auxilio_vial === true && service_location_type_id?.service_location_code === 'FORANEO' && manejar_tarifas_establecidas === false ? schema.required('El monto de casetas debe ser mayor o igual a 0').min(0, 'El monto de casetas debe ser mayor o igual a 0') : schema.nullable());
    }),



    montacargas_costo_hora: yup.number().when(['montacarga', 'manejar_tarifas_establecidas'], ([montacarga, manejar_tarifas_establecidas], schema) => {
        return (montacarga === true && manejar_tarifas_establecidas === false ? schema.required('El costo por hora debe ser mayor o igual a 0').min(0, 'El costo por hora debe ser mayor o igual a 0') : schema.nullable());
    }),
    montacargas_horas: yup.number().when(['montacarga', 'manejar_tarifas_establecidas'], ([montacarga, manejar_tarifas_establecidas], schema) => {
        return (montacarga === true && manejar_tarifas_establecidas === false ? schema.required('El número de horas debe ser mayor o igual a 0').min(0, 'El número de horas debe ser mayor o igual a 0') : schema.nullable());
    }),
    montacargas_subtotal: yup.number().when(['montacarga', 'manejar_tarifas_establecidas'], ([montacarga, manejar_tarifas_establecidas], schema) => {
        return (montacarga === true ? schema.required('El subtotal debe ser mayor o igual a 0').min(0, 'El subtotal debe ser mayor o igual a 0') : schema.nullable());
    }),
    montacargas_traslado: yup.number().when(['montacarga', 'manejar_tarifas_establecidas'], ([montacarga, manejar_tarifas_establecidas], schema) => {
        return (montacarga === true && manejar_tarifas_establecidas === false ? schema.required('El monto de traslado debe ser mayor o igual a 0').min(0, 'El monto de traslado debe ser mayor o igual a 0') : schema.nullable());
    }),

    corresponsalia_subtotal: yup.number().when('corresponsalia', ([corresponsalia], schema) => {
        return (corresponsalia === true ? schema.required('El monto de corresponsalía debe ser mayor o igual a 0').min(0, 'El monto de corresponsalía debe ser mayor o igual a 0') : schema.nullable());
    }),
    corresponsalia_observaciones: yup.string().nullable(),
    taxi_subtotal: yup.number().when('taxi', ([taxi], schema) => {
        return (taxi === true ? schema.required('El monto de taxi debe ser mayor o igual a 0').min(0, 'El monto de taxi debe ser mayor o igual a 0') : schema.nullable());
    }),
    someWithShielding: yup.boolean(),
    someWithCarga: yup.boolean(),
    someWithVolumen: yup.boolean(),
    monto_blindaje: yup.number().when('someWithShielding', ([someWithShielding], schema) => {
        return (someWithShielding === true ? schema.required('El monto de blindaje debe ser mayor o igual a 0').min(0, 'El monto de blindaje debe ser mayor o igual a 0') : schema.nullable());
    }),
    monto_carga_volumen: yup.number().when(['someWithCarga', 'someWithVolumen'], ([someWithCarga, someWithVolumen], schema) => {
        return ((someWithCarga || someWithVolumen) ? schema.required('El monto de carga/volumen debe ser mayor o igual a 0').min(0, 'El monto de carga/volumen debe ser mayor o igual a 0') : schema.nullable());
    }),
    montosExtras: yup.array().of(
        yup.object().shape({
            monto_extra_descripcion: yup.string().required("Escriba la descripción del monto"),
            monto_extra_monto: yup.number().required("Escriba el monto").positive('El monto debe ser positivo'),
        })
    ),


    servicio_muerto_monto_penalizacion: yup.number().when(['withPenalizacion'], ([withPenalizacion], schema) => {
        return (withPenalizacion ? schema.required('El monto de penalización debe ser mayor o igual a 0').min(0, 'El monto de penalización debe ser mayor o igual a 0') : schema.nullable());
    }),



    manejar_precios_netos: yup.boolean(),
    manejar_tarifas_establecidas: yup.boolean().when('service_location_type_id', ([service_location_type_id], schema) => {
        return (service_location_type_id?.service_location_code === 'FORANEO' ? schema.required('Se requiere seleccionar la Modalidad de Tarifa') : schema.nullable());
    }),
    venta_publico: yup.boolean(),
    subtotal: yup.number().required("El subtotal es requerido").positive('El subtotal debe ser positivo'),
    iva: yup.number(),
    total: yup.number().required("El total es requerido").positive('El total debe ser positivo'),

    payments_contado: yup.array().of(
        yup.object().shape({
            payment_date: yup.date().required("Seleccione la fecha de pago"),
            payment_method_code: yup.string().required("Seleccione el método de pago"),
            payment_amount: yup.number().required("Escriba el monto").positive('El monto debe ser positivo'),
            bank_account_id: yup.object().when(['payment_method_code'], ([payment_method_code], schema) => {
                return (payment_method_code === 'TRANSFER' ? schema.required('La cuenta bancaria es obligatoria al seleccionar la transferencia') : schema.nullable());
            }),
        }),
    ),
    payments_credito: yup.array().of(
        yup.object().shape({
            payment_date: yup.date().required("Seleccione la fecha de pago"),
            payment_method_code: yup.string().required("Seleccione el método de pago"),
            payment_amount: yup.number().required("Escriba el monto").positive('El monto debe ser positivo'),
            bank_account_id: yup.object().when(['payment_method_code'], ([payment_method_code], schema) => {
                return (payment_method_code === 'TRANSFER' ? schema.required('La cuenta bancaria es obligatoria al seleccionar la transferencia') : schema.nullable());
            }),
        }),
    ),
    invoices_contado: yup.array().of(
        yup.object().shape({
            invoice_number: yup.string().required("Escribe el número de factura"),
            invoice_date: yup.date().required("Seleccione la fecha de la factura"),
            invoice_amount: yup.number().required("Escriba el monto").positive('El monto debe ser positivo'),
            bank_account_id: yup.object().when(['payment_method_code'], ([payment_method_code], schema) => {
                return (payment_method_code === 'TRANSFER' ? schema.required('La cuenta bancaria es obligatoria al seleccionar la transferencia') : schema.nullable());
            }),
        }),
    ),
    invoices_credito: yup.array().of(
        yup.object().shape({
            invoice_number: yup.string().required("Escribe el número de factura"),
            invoice_date: yup.date().required("Seleccione la fecha de la factura"),
            invoice_amount: yup.number().required("Escriba el monto").positive('El monto debe ser positivo'),
            bank_account_id: yup.object().when(['payment_method_code'], ([payment_method_code], schema) => {
                return (payment_method_code === 'TRANSFER' ? schema.required('La cuenta bancaria es obligatoria al seleccionar la transferencia') : schema.nullable());
            }),
        }),
    ),

    //Evaluación
    /*extinguidor: yup.boolean(),
    llanta_refaccion: yup.boolean(),
    gato: yup.boolean(),
    herramientas: yup.boolean(),
    herramientas_observaciones: yup.string(),
    cable_pasa_corriente: yup.boolean(),
    senalamientos: yup.boolean(),
    otros: yup.boolean(),
    otros_observaciones: yup.string(),

    satisfecho: yup.number(),
    operador_amable: yup.number(),
    grua_limpia: yup.number(),
    recomendaria_servicio: yup.number(),*/
    /*photos: yup.array()
        .max(8, 'Puedes cargar como máximo 8 fotos')
        .required('Selecciona al menos una foto'),*/
});

export const quickOrderValidationSchema = yup.object().shape({
    //folio: yup.string().required("Agregar un folio"),
    precio: yup.string().required("Agregar un precio"),
    contado: yup.boolean().required("¿Es de contado?"),
    operador: yup.object().test(
        'non-empty-object', 
        'El campo operador no puede estar vacío', 
        (value) => value && Object.keys(value).length > 0
      ),
    crane: yup.object().test(
        'non-empty-object', 
        'El campo grua no puede estar vacío', 
        (value) => value && Object.keys(value).length > 0
      )
  });
  
  
  export const quickOrderInitialValues = {
    folio: '',
    operador: {},
    crane: {},
    precio: '',
    contado: false,
    grua: false,
    auxilio_vial: false,
    montacarga: false,
    corresponsalia: false,
    carta_porte: false,
    cliente_vip: false,
    iva: 0.0,
    total: 0.0,
    montosExtras: [],
    vehiculos:[],
    order_locations: [],
    expediente:'',
    beneficiario_servicio:'',
    empresa:'',
    need_invoice:false,
    invoice_folio:'',
    invoice_generation_date:'',
    service_location_type_id:{value:1},
    orderStatuses: {
        "SIN_ASIGNAR": {
            "has_passed_status": false
        },
        "ASIGNADA_SIN_ENVIAR": {
            "has_passed_status": false
        },
        "ENVIADA": {
            "has_passed_status": false
        },
        "CONTACTO": {
            "has_passed_status": false
        },
        "LLEGADA": {
            "has_passed_status": false
        },
        "PAGADO_CONTADO": {
            "has_passed_status": false
        },
        "ENTREGA": {
            "has_passed_status": false
        },
        "EVALUADO": {
            "has_passed_status": false
        },
        "PAGADO_CREDITO": {
            "has_passed_status": false
        },
        "CIERRE": {
            "has_passed_status": false
        },
        "CANCELADO_PAGO_PENDIENTE": {
            "has_passed_status": false
        },
        "CANCELADO": {
            "has_passed_status": false
        },
        "FACTURAR": {
            "has_passed_status": false
        }
    },

    edit_locations_finished: false,
  };

export const orderInitialValues = {
    es_cita: opcionesEsCita[0],
    fecha_hora_cita: "",
    fecha_hora_alerta_cita: "",
    fecha_hora_termino: "",
    alerta_antes_hora_cita: "",
    expediente: "",
    reporte: "",
    tipo_pago_id: '',
    fecha_hora_contacto: "",
    fecha_hora_entrega: "",

    //información del servicio	
    grua: false,
    grua_vial: '',
    auxilio_vial: false,
    montacarga: false,
    corresponsalia: false,
    taxi: false,
    tipo_servicio_observaciones: "",

    //Datos de los bienes	
    carta_porte: false,
    vehiculos: [
        {
            vehicle_type_id: '',
            brand_id: '',
            vehicle_model_id: '',
            anio: '',
            color_id: '',
            observaciones: '',
            placas: '',
            numero_serie: '',
            shielding_type_id: '',
            carga_kg: '',
            registrar_volumen: false,
            volumen_largo: '',
            volumen_ancho: '',
            volumen_altura: '',
            volumen_m3: '',
            estado_llantas_giran: '',
            estado_volante_gira: '',
            estado_pie_calle: '',
            estado_neutral: '',
            estado_en_cajon: '',
            asistencia_paso_corriente: '',
            asistencia_combustible: '',
            asistencia_cambio_neumatico: '',
            asistencia_cerrajeria: ''
        }
    ],
    bienes_observaciones: "",

    //Datos de contacto y seguimiento
    client_type_id: '',
    insurance_id: '',
    autorizacion_cabina: "",
    empresa: "",
    cliente_vip: false,
    beneficiario_servicio: "",
    telefono_principal: "",
    numero_poliza: "",
    numero_siniestro: "",
    numero_folio: "",
    excedente_asegurado: '',
    monto_aseguradora_paga: '',
    datos_contacto_seguimiento_observaciones: "",

    //Ubicación de la atención	
    service_location_type_id: "",
    order_locations: [],

    registrar_ubicacion_destino: false,
    registrar_ubicacion_origen: false,

    nombre_ubicacion_origen: "",
    nombre_ubicacion_destino: "",

    distancia_ubicacion_origen: "",
    distancia_ubicacion_destino: "",

    edit_locations_finished: false,
    //Cálculo de montos	
    ubicacion_tarifaria_origen_id: "",
    ubicacion_tarifaria_destino_id: "",
    //ubicacion_tarifaria_destino_id: "", 
    km: "",
    crane_id: null,
    montacargas: '',
    operadores: '',
    base_id: "",

    grua_tarifa_kilometro: "",
    grua_subtotal: "",
    grua_banderazo: "",
    grua_casetas: "",

    aux_vial_tarifa_kilometro: "",
    aux_vial_subtotal: "",
    aux_vial_banderazo: "",
    aux_vial_casetas: "",

    montacargas_costo_hora: "",
    montacargas_horas: "",
    montacargas_subtotal: "",
    montacargas_traslado: "",

    corresponsalia_subtotal: "",
    corresponsalia_observaciones: "",
    taxi_subtotal: "",
    monto_blindaje: "",
    monto_carga_volumen: "",
    servicio_muerto_monto_penalizacion: "",

    montosExtras: [],

    manejar_precios_netos: false,
    manejar_tarifas_establecidas: false,
    venta_publico: false,
    subtotal: "",
    iva: "",
    total: "",
    someWithShielding: false,
    withPenalizacion: false,
    orderStatuses: {
        "SIN_ASIGNAR": {
            "has_passed_status": false
        },
        "ASIGNADA_SIN_ENVIAR": {
            "has_passed_status": false
        },
        "ENVIADA": {
            "has_passed_status": false
        },
        "CONTACTO": {
            "has_passed_status": false
        },
        "LLEGADA": {
            "has_passed_status": false
        },
        "PAGADO_CONTADO": {
            "has_passed_status": false
        },
        "ENTREGA": {
            "has_passed_status": false
        },
        "EVALUADO": {
            "has_passed_status": false
        },
        "PAGADO_CREDITO": {
            "has_passed_status": false
        },
        "CIERRE": {
            "has_passed_status": false
        },
        "CANCELADO_PAGO_PENDIENTE": {
            "has_passed_status": false
        },
        "CANCELADO": {
            "has_passed_status": false
        },
        "FACTURAR": {
            "has_passed_status": false
        }
    },

    //Evaluación
    /*extinguidor: null,
     llanta_refaccion: null,
     gato: null,
     herramientas: null,
     herramientas_observaciones: null,
     cable_pasa_corriente: null,
     senalamientos: null,
     otros: null,
     otros_observaciones: null,
     satisfecho: '',
     operador_amable: '',
     grua_limpia: '',
     recomendaria_servicio: '',*/

    photos: [],
    need_invoice:false,
    invoice_folio:'',
    invoice_generation_date:'',
};