import React from "react";
import "./loader.css";

const Loader = ({ height = "100vh", isLoading = false, disabled = false }) => {
  const [dots, setDots] = React.useState(".");

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots) => (dots.length < 3 ? dots + "." : "."));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  if (!isLoading) return <></>;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: "rgba(0, 0, 0, 0.5)",
        backgroundColor: "rgb(168 168 168 / 63%)",
        height,
        flexDirection: "column",
        zIndex: 9999,
      }}
    >
      <img
        alt="Logo"
        width={400}
        src="/assets/img/varo/logo.png"
        className="ld ld-dim breathing"
        style={{
          cursor: "pointer",
          animation: "breathing 2s ease infinite",
          position: "relative",
          zIndex: 1,
        }}
      />
      <h4 style={{ marginTop: "20px", color: "black" }}>Cargando{dots}</h4>
    </div>
  );
};

export default Loader;