import * as React from "react";
import { Panel, PanelBody, PanelHeader } from "../../../../components/panel/panel";
import { Form, Row, Col } from "react-bootstrap";
import axiosClient from '../../../../config/axios.js';
import './ComponentToPrint.css';
import OrderFieldsDatosBienes from "../orderSections/orderFieldsDatosBienes";
import OrderVehicles from "../orderVehicles/orderVehicles";
import OrderVehiclesPrint from "./orderVehiclesPrint";
import OrderContactoPrint from "./orderContactoPrint";
import OrderUbicacionPrint from "./orderUbicacionPrint";
import OrderMontosPrint from "./orderMontosPrint";

import { format } from 'date-fns';
import { es } from 'date-fns/locale';


export class ComponentToPrint extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            checked: false,
            orderData: null
        };
    }

    canvasEl;

    async componentDidMount() {
        const { id } = this.props;
        const { orderData } = this.props;


        this.setState({ orderData: orderData });
        //await this.getLastOrderStatusesGroupedByOrderStatus(id);

    }

    async getLastOrderStatusesGroupedByOrderStatus(order_id) {
        try {
            try {
                const response = await axiosClient.get(`/orders/getLastOrderStatusesGroupedByOrderStatus/${order_id}`);

            } catch (error) {
                console.error(error);
            }
        } catch (error) {
            console.error(error);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderData !== this.props.orderData) {
            this.setState({ orderData: this.props.orderData });
            //this.getLastOrderStatusesGroupedByOrderStatus(this.props.id);
        }
    }

    handleCheckboxOnChange = () =>
        this.setState({ checked: !this.state.checked });

    setRef = (ref) => (this.canvasEl = ref);

    getPageWidth = () => {
        // Define el ancho de la página en milímetros
        const pageWidth = '216mm'; // A4 paper size width

        // Retorna el ancho de la página como estilo CSS
        return { width: pageWidth };
    };

    getPageMargins = () => {
        // Define los márgenes personalizados aquí
        const marginTop = '10mm';
        const marginRight = '5mm';
        const marginBottom = '10mm';
        const marginLeft = '5mm';

        // Retorna las reglas de estilo CSS para los márgenes de la página
        return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }
        
        `;
    };



    render() {
        const { orderData } = this.state;

        return (
            <>
                <div style={this.getPageWidth()} className="m-auto">
                    <style>{this.getPageMargins()}</style>
                    <div className="m-0">
                        <Row className="mb-0 align-items-center">
                            <div className="col-sm-8">
                                <Row className="align-items-center m-0">
                                    <div className="col-sm-4">
                                        <div className="text-center">
                                            <img alt="A test" src="/assets/img/varo/logo.png" className="img-fluid" />
                                        </div>
                                    </div>


                                    <div className="offset-sm-2 col-sm-6">
                                        <div className="text-center fw-bold">CARTA DE SERVICIO/C. PORTE</div>
                                        <div className="text-center fw-bold text-red">FOLIO: {orderData?.folio ? `No. ${orderData?.folio?.toString()?.padStart(5, '0')}` : ''}</div>
                                    </div>
                                </Row>
                                <Row className="align-items-center">

                                    <small className="mt-1 simple-text">
                                        R.F.C. VABC-620921-AI9 BLvd. Eduardo Vasconcelos 207-D, Col. Jalatlaco, Oaxaca, Oax.
                                        <br></br>C.P. 68080 Tel./Fax. (951) 515-05-95 E-mail: gruasvaro@prodigy.net.mx
                                    </small>
                                </Row>

                            </div>

                            <div className="col-sm-4">
                                {1 === 0 && (
                                    <Row className="mb-0 mt-0 pb-0 pt-0">
                                        <label className="form-label col-form-label float-end fs-9px col-sm-4">FOLIO</label>
                                        <div className="col-sm-8 m-b-0 pb-0 mt-0 pt-0">
                                            <input type="text" className="form-control my-small-input bg-white text-red bold" disabled value={orderData?.folio ? `No. ${orderData?.folio?.toString()?.padStart(5, '0')}` : ''} />
                                        </div>
                                    </Row>
                                )}

                                <Row className="mb-0 mt-0 pb-0 pt-0">

                                    {orderData?.fecha_hora_cita && (
                                        <Col sm='12'>

                                            <div className="text-center fw-bold fs-14px">
                                                <span className="fa fa-clock me-1" />
                                                CITA: {' '}
                                                {orderData?.fecha_hora_cita && format(
                                                    new Date(orderData.fecha_hora_cita),
                                                    'EEEE dd/MMM/yyyy',
                                                    { locale: es }
                                                )} {orderData?.fecha_hora_cita && format(
                                                    new Date(orderData?.fecha_hora_cita),
                                                    'hh:mm a',
                                                    { locale: es }
                                                )}
                                            </div>
                                        </Col>
                                    )}

                                    <Col sm='12'>
                                        <div className="form-floating ">
                                            <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={`${orderData?.fecha_hora_reporte && format(
                                                new Date(orderData.fecha_hora_reporte),
                                                'dd/MMM/yyyy',
                                                { locale: es }
                                            )} ${orderData?.fecha_hora_reporte && format(
                                                new Date(orderData?.fecha_hora_reporte),
                                                'hh:mm a',
                                                { locale: es }
                                            )} por ${orderData?.base_id ? orderData?.base_id.label : ''}`} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">ORDEN REGISTRADA EL</label>
                                        </div>
                                    </Col>

                                    <Col sm='6'>
                                        <span className="simple-text">EXPEDIENTE:</span>
                                        <textarea
                                            className="form-control my-medium-floating-textarea form-print"
                                            value={orderData?.expediente || ''}
                                        />
                                    </Col>

                                    <Col sm='6'>
                                        <span className="simple-text">FOLIO DE PAGO:</span>
                                        <textarea
                                            className="form-control my-medium-floating-textarea form-print"
                                            value={orderData?.reporte || ''}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Row>


                        <form className="row row-cols-sm-auto align-items-center" action="/" method="POST">
                            <div className={`form-check mt-0 mb-0 ${orderData?.tipo_servicio_observaciones ? 'col-sm-2' : 'col-sm-2'}`}>
                                <input className="form-check-input my-small-input mt-1" type="checkbox" checked={orderData?.grua ? true : false} onChange={() => { }} />
                                <label className="form-check-label fs-9px" htmlFor="flexCheckDefault">
                                    GRÚA {orderData?.grua_vial !== null ? (orderData?.grua_vial ? 'VIAL' : 'SINIESTRO') : ''}
                                </label>
                            </div>

                            <div className={`form-check mt-0 mb-0 ${orderData?.tipo_servicio_observaciones ? 'col-sm-2' : 'col-sm-2'}`}>
                                <input className="form-check-input my-small-input mt-1" type="checkbox" checked={orderData?.auxilio_vial ? true : false} onChange={() => { }} />
                                <label className="form-check-label fs-9px" htmlFor="flexCheckDefault">
                                    AUXILIO VIAL
                                </label>
                            </div>

                            <div className={`form-check mt-0 mb-0 ${orderData?.tipo_servicio_observaciones ? 'col-sm-2' : 'col-sm-2'}`}>
                                <input className="form-check-input my-small-input mt-1" type="checkbox" checked={orderData?.montacarga ? true : false} onChange={() => { }} />
                                <label className="form-check-label fs-9px" htmlFor="flexCheckDefault">
                                    MONTACARGAS
                                </label>
                            </div>

                            <div className={`form-check mt-0 mb-0 ${orderData?.tipo_servicio_observaciones ? 'col-sm-2' : 'col-sm-2'}`}>
                                <input className="form-check-input my-small-input mt-1" type="checkbox" checked={orderData?.corresponsalia ? true : false} onChange={() => { }} />
                                <label className="form-check-label fs-9px" htmlFor="flexCheckDefault">
                                    CORRESPONSALÍA
                                </label>
                            </div>

                            <div className={`form-check mt-0 mb-0 ${orderData?.tipo_servicio_observaciones ? 'col-sm-1' : 'col-sm-2'}`}>
                                <input className="form-check-input my-small-input mt-1" type="checkbox" checked={orderData?.taxi ? true : false} onChange={() => { }} />
                                <label className="form-check-label fs-9px" htmlFor="flexCheckDefault">
                                    TAXI
                                </label>
                            </div>

                            {orderData?.tipo_servicio_observaciones && (
                                <Col sm='3'>
                                    <Form.Group as={Col} md="12" className="simple-text">
                                        <Form.Label className="">OBSERVACIONES SOBRE EL TIPO DE SERVICIO:</Form.Label>
                                        <span className="">{' ' + orderData?.tipo_servicio_observaciones}</span>
                                    </Form.Group>
                                </Col>
                            )}
                        </form>





                        {orderData?.grua || orderData?.auxilio_vial || orderData?.corresponsalia || orderData?.montacarga || orderData?.taxi ? (
                            <>

                                {(orderData?.grua || orderData?.auxilio_vial || orderData?.corresponsalia || orderData?.montacarga) && (
                                    <Panel className='mt-0 mb-1'>
                                        <PanelHeader noButton={true} className='pt-0 pb-0 ps-1'>
                                            <span className="panel-title">
                                                DATOS DE LOS BIENES {orderData?.carta_porte ? ' - CARTA PORTE REQUERIDA' : ''}
                                            </span>
                                        </PanelHeader>
                                        <PanelBody className='pt-0 mb-0 pb-0'>
                                            <OrderVehiclesPrint
                                                orderData={orderData}
                                            />
                                        </PanelBody>
                                    </Panel>
                                )}


                                <Panel className='mt-0 mb-1'>
                                    <PanelHeader noButton={true} className='pt-0 pb-0 ps-1'><span className="panel-title">DATOS DE CONTACTO Y SEGUIMIENTO</span></PanelHeader>
                                    <PanelBody className='pt-1 mb-0 pb-0'>
                                        <OrderContactoPrint
                                            orderData={orderData}
                                        />
                                    </PanelBody>
                                </Panel>

                                <Panel className='mt-0 mb-1'>
                                    <PanelHeader noButton={true} className='pt-0 pb-0 ps-1'><span className="panel-title">UBICACIÓN DE LA ATENCIÓN - {orderData?.service_location_type_id?.label && orderData?.service_location_type_id?.label.toUpperCase()}</span></PanelHeader>
                                    <PanelBody className='pt-1 mb-0 pb-0'>
                                        <OrderUbicacionPrint
                                            orderData={orderData}
                                        />
                                    </PanelBody>
                                </Panel>


                                <Panel className='mt-0 mb-1'>
                                    <PanelHeader noButton={true} className='pt-0 pb-0 ps-1'><span className="panel-title">MONTOS</span></PanelHeader>
                                    <PanelBody className='pt-1 mb-0 pb-0'>
                                        <OrderMontosPrint
                                            orderData={orderData}
                                        />
                                    </PanelBody>
                                </Panel>
                            </>

                        ) : null}



                        <Panel className='mt-0 mb-0'>
                            <PanelHeader noButton={true} className='pt-0 pb-0 ps-1'><span className="panel-title">COMENTARIOS</span></PanelHeader>
                            <PanelBody className='pb-0'>
                                <Row>
                                    <div className="col-sm-7">
                                        <Row className="mb-4 mt-2">
                                            <hr className="bg-gray-500 mt-0 mb-0" />
                                        </Row>
                                        <Row className="mb-4 mt-4">
                                            <hr className="bg-gray-500 mt-0 mb-0" />
                                        </Row>
                                        <Row className="mb-4">
                                            <hr className="bg-gray-500 mt-0 mb-0" />
                                        </Row>

                                    </div>
                                    <div className="col-sm-5 text-center">
                                        <Row className="pb-4">
                                            <span className='fs-9px text-wrap '>EL SERVICIO DE GRÚA SE REALIZÓ SIN NINGÚN DAÑO AL VEHÍCULO</span>
                                        </Row>
                                        <Row className="pb-2">
                                        </Row>
                                        <Row className="mt-4 ms-1 me-1">
                                            <hr className="bg-black-500 mt-0 mb-0" />
                                        </Row>
                                        <Row className="mt-1">
                                            <span className='fs-9px text-wrap '>NOMBRE Y FIRMA</span>
                                        </Row>
                                    </div>
                                </Row>
                            </PanelBody>
                        </Panel>

                        <Row className="mt-1 mb-1 ms-1 me-1">
                            <Col sm='6'>
                                <div className="form-floating ">
                                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.fecha_hora_reporte ? `${orderData?.fecha_hora_reporte && format(
                                        new Date(orderData?.fecha_hora_reporte),
                                        'EEEE dd/MMM/yyyy',
                                        { locale: es }
                                    )} ${orderData?.fecha_hora_reporte && format(
                                        new Date(orderData?.fecha_hora_reporte),
                                        'hh:mm a',
                                        { locale: es }
                                    )}` : ''} />
                                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">FECHA Y HORA DE REGISTRO</label>
                                </div>
                            </Col>
                            <Col sm='6'>
                                <div className="form-floating ">
                                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.fecha_hora_termino ? `${orderData?.fecha_hora_termino && format(
                                        new Date(orderData?.fecha_hora_termino),
                                        'EEEE dd/MMM/yyyy',
                                        { locale: es }
                                    )} ${orderData?.fecha_hora_termino && format(
                                        new Date(orderData?.fecha_hora_termino),
                                        'hh:mm a',
                                        { locale: es }
                                    )}` : ''} />
                                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">FECHA Y HORA DE EVALUACIÓN</label>
                                </div>
                            </Col>
                        </Row>


                        <div className="flash" />

                    </div>
                </div>
            </>

        );
    }
}

export const FunctionalComponentToPrint = React.forwardRef((props, ref) => {
    // eslint-disable-line max-len
    return <ComponentToPrint ref={ref} text={props.text} />;
});
