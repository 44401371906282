import React, {useEffect} from 'react';
import Select from 'react-select';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import CurrencyInput from 'react-currency-input-field';
import InputGroup from 'react-bootstrap/InputGroup';
import { ErrorMessage, FieldArray } from 'formik';
import OrderStatusAsignadaSinEnviarSeleccOperador from '../orderStatus/orderStatusAsignadaSinEnviarSeleccOperador';
import axios from 'axios';
import axiosClient from '../../../../config/axios.js';

function OrderAmountsVariables({ gruas, operadores, montacargas, vehiculosAsistencia, values, handleChange, setFieldTouched, setFieldValue, errors }) {

    const handleNearby =(e)=>{
        let craneType = e.crane_type ? e .crane_type:e?.crane_type_name?.split(",")
        for (const i in values.order_locations){
            let location = values.order_locations[i]
            axiosClient.get(`/locations/nearby`, {
                params: { longitude:location.longitude, latitude:location.latitude, radius:10000, tipos:craneType },
                })
                .then(response => {
                    if (response.data?.data?.length > 0) {
                        /*const newData = response.data.data.map(item => ({
                            lat: parseFloat(item.latitude),
                            lng: parseFloat(item.longitude),
                            id:item.id,
                            folio:item?.folio.toString().padStart(5, '0'),
                            order_id:item.order_id,
                            subtotal:item.subtotal,
                            service_location_type_description:item.service_location_type_description,
                            grua: item.grua, auxilio_vial: item.auxilio_vial, montacarga: item.montacarga, 
                            corresponsalia: item.corresponsalia, taxi: item.taxi,
                            distance:item.distance, total:item.total
    
                        }));*/
                        setFieldValue(`order_locations.${i}.location_nearby`, response.data?.data);
    
                    }
                })
                .catch(error => {
                    console.error('Error fetching location data:', error);
                });
        }
        /*axiosClient.get(`/locations/nearby`, {
            params: { longitude, latitude, radius },
            })
            .then(response => {
                console.log('entro en response es ', response)
                if (response.data?.data?.length > 0) {*/
                    /*const newData = response.data.data.map(item => ({
                        lat: parseFloat(item.latitude),
                        lng: parseFloat(item.longitude),
                        id:item.id,
                        folio:item?.folio.toString().padStart(5, '0'),
                        order_id:item.order_id,
                        subtotal:item.subtotal,
                        service_location_type_description:item.service_location_type_description,
                        grua: item.grua, auxilio_vial: item.auxilio_vial, montacarga: item.montacarga, 
                        corresponsalia: item.corresponsalia, taxi: item.taxi,
                        distance:item.distance, total:item.total

                    }));*/
                    /*setFieldValue(`order_locations.${address.index ==""? 0:address.index}.location_nearby`, response.data?.data);

                }
            })
            .catch(error => {
                console.error('Error fetching location data:', error);
            });*/
    }
    
    useEffect(() => {
        if(values?.crane_id?.value && values?.order_locations?.length>0)
        handleNearby(values?.crane_id)
        }, []);
        
    return (
        <>
            {values.service_location_type_id.service_location_code === 'FORANEO' && values.manejar_tarifas_establecidas === false && (
                <Row className="mb-3">

                    <Form.Group as={Col} md="12" >
                        <Form.Label>Distancia ajustada para tarifa (km.)</Form.Label>
                        <InputGroup >

                            <CurrencyInput
                                autoComplete='off'
                                allowDecimals={false}
                                className={`form-control ${errors.km ? 'is-invalid' : ''}`}
                                prefix=""
                                allowNegativeValue={false}
                                decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                decimalScale={2}
                                decimalSeparator="."
                                groupSeparator=","
                                name="km"
                                value={values.km}
                                onValueChange={(value) => setFieldValue('km', value)}
                                disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                            />
                            <InputGroup.Text >km.</InputGroup.Text>
                        </InputGroup>
                        <span className='invalid-feedback' style={{ display: 'block' }}>
                            <ErrorMessage name="km" />
                        </span>

                    </Form.Group>


                </Row>
            )}




            {(values.someWithShielding || values.someWithCarga || values.someWithVolumen) && (
                <Row className='mb-1'>
                    <div className="col-md-6 pb-2">
                        <div className="alert alert-warning alert-dismissible fade show mb-0">
                            Se encuentra registrado al menos un vehículo con blindaje, carga y/o volumen
                            <button type="button" className="btn-close" data-bs-dismiss="alert"></button>
                        </div>
                    </div>

                </Row>
            )}


            <Row className="mb-3" >

                {(values.grua || values.montacarga || values.corresponsalia) && (
                    <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>{values.montacarga ? 'Grúa que trasladará el montacargas' : 'Grúa No.'}</Form.Label>
                        <Select
                            name="crane_id"
                            options={gruas}
                            className={errors.crane_id ? 'is-invalid' : ''}
                            placeholder="Selecciona una grúa"
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            isClearable={true}
                            isSearchable={true}
                            value={values.crane_id}
                            onChange={(e) => {
                                setFieldTouched("crane_id", true);
                                setFieldValue("crane_id", e);
                                handleNearby(e)
                            }}
                            isDisabled={values.orderStatuses?.CIERRE?.has_passed_status}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.crane_id}
                        </Form.Control.Feedback>


                    </Form.Group>
                )}


                {(values.auxilio_vial || values.taxi) && (
                    <Form.Group as={Col} md="6" name="orderAmountsVariables_vehiculoAsistenciaVial">
                        <Form.Label>Vehículo{values.vehiculos_asistencia && values.vehiculos_asistencia.length > 1 ? 's' : ''} de asistencia Vial</Form.Label>
                        <Select
                            name="vehiculos_asistencia"
                            options={vehiculosAsistencia}
                            className={errors.vehiculos_asistencia ? 'is-invalid' : ''}
                            placeholder="Selecciona uno o más vehículos de asistencia"
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            isClearable={true}
                            isSearchable={true}
                            value={values.vehiculos_asistencia}
                            isMulti
                            onChange={(e) => {
                                setFieldValue("vehiculos_asistencia", e ? e : '');
                            }}
                            isDisabled={values.orderStatuses?.CIERRE?.has_passed_status}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.vehiculos_asistencia}
                        </Form.Control.Feedback>

                    </Form.Group>
                )}

                {values.montacarga ?
                    <Form.Group as={Col} md="6" name="orderAmountsVariables_sinAsignarMontacargas">
                        <Form.Label>Montacargas</Form.Label>
                        <Select
                            name="montacargas"
                            options={montacargas}
                            className={errors.montacargas ? 'is-invalid' : ''}
                            placeholder="Selecciona uno o más montacargas"
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            isClearable={true}
                            isSearchable={true}
                            value={values.montacargas}
                            isMulti
                            onChange={(e) => {
                                setFieldValue("montacargas", e ? e : '');
                            }}
                            isDisabled={values.orderStatuses?.ENVIADA?.has_passed_status}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.montacargas}
                        </Form.Control.Feedback>

                    </Form.Group>
                    : ''
                }


                <Form.Group as={Col} md="6" name="orderAmountsVariables_sinAsignarOperador">

                    <OrderStatusAsignadaSinEnviarSeleccOperador
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        operadores={operadores}
                    />


                </Form.Group>

            </Row>



        </>
    );
}

export default OrderAmountsVariables;