import React, { useRef } from 'react';

import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import DateTime from 'react-datetime';
import moment from 'moment';

function OrderStatusLlegada({ fecha_hora_contacto, fetchCurrentOrderStatusByOrder, setCurrentOrderStatus, modalInstance, reloadOrderDataFromDB }) {
    const { id } = useParams();
    const { Formik } = formik;

    const fechaHoraLlegada = useRef(null);
    const fechaHoraContacto = useRef(null);

    return (
        <>
            <Formik
                validationSchema={yup.object().shape({
                    fecha_hora_llegada: yup.date().required("La fecha y hora de llegada es requerida.").min(moment(fecha_hora_contacto).add(1, 'second'), 'La fecha y hora de llegada debe ser posterior a la de contacto'),
                    observaciones: yup.string()
                })}
                initialValues={{
                    fecha_hora_llegada: fecha_hora_contacto >= new Date() ? moment(fecha_hora_contacto).add(1, 'minute') : new Date(),
                    observaciones: ''
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    axiosClient.put(`/orders/updateCurrentOrderStatus/${id}`, {
                        order_status_code: 'LLEGADA',
                        fecha_hora_llegada: moment(values.fecha_hora_llegada).format(),
                        observations: values.observaciones
                    }).then(async response => {
                        const result = await fetchCurrentOrderStatusByOrder();
                        setCurrentOrderStatus(result);
                        console.table("setCurrentOrderStatus", result);

                        if (modalInstance) {
                            modalInstance.hide();
                        }
                        reloadOrderDataFromDB();

                    }).catch(err => {
                        console.log(err.response);
                        Swal.fire({
                            title: 'Ups!',
                            text: 'Ha ocurrido un error',
                            icon: 'error',
                            confirmButtonText: 'Cerrar',
                            cancelButtonText: 'Cancelar',
                            showCancelButton: false,
                        });

                    });
                }}

            >

                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (
                    <>
                        <div className="modal-header">
                            <h4 className="modal-title">Registrar Fecha y hora de Llegada</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <div className="modal-body">
                            <Form onSubmit={handleSubmit}>

                                <Row className="mb-4">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Fecha y hora de contacto registrada</Form.Label>
                                        <DateTime
                                            disabled={true}
                                            name="fecha_hora_contacto"
                                            ref={fechaHoraContacto}
                                            inputProps={{
                                                placeholder: 'Fecha y hora de contacto',
                                                disabled: true
                                            }}
                                            locale="es"
                                            value={fecha_hora_contacto}
                                            dateFormat="dddd DD/MMMM/YYYY"
                                            timeFormat="hh:mm A"
                                            initialViewMode="days"
                                            // updateOnView="time"
                                        />
                                    </Form.Group>
                                </Row>


                                <Row className="mb-1">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Fecha y hora de llegada</Form.Label>
                                        <DateTime
                                            name="fecha_hora_llegada"
                                            ref={fechaHoraLlegada}
                                            inputProps={{ placeholder: 'Fecha y hora de llegada', readOnly: true }}
                                            closeOnSelect={false}
                                            locale="es"
                                            value={values.fecha_hora_llegada}
                                            className={errors.fecha_hora_llegada ? 'is-invalid' : ''}
                                            dateFormat="dddd DD/MMMM/YYYY"
                                            timeFormat="hh:mm A"
                                            initialViewMode="days"
                                            onOpen={(e) => {
                                                fechaHoraLlegada.current.navigate('days');
                                                setFieldTouched('fecha_hora_llegada', true);
                                            }}
                                            onChange={(e) => {
                                                setFieldValue("fecha_hora_llegada", e ? e : '');
                                            }}
                                            isInvalid={touched.fecha_hora_llegada && !!errors.fecha_hora_llegada}
                                            // updateOnView="time"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.fecha_hora_llegada}
                                        </Form.Control.Feedback>


                                    </Form.Group>
                                </Row>

                                <Row className="mb-1">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Observaciones</Form.Label>
                                        <textarea
                                            name="observaciones"
                                            className={`form-control ${errors.observaciones ? 'is-invalid' : ''}`}
                                            onChange={(e) => {
                                                setFieldValue('observaciones', e.target.value);
                                            }}
                                            value={values.observaciones}
                                        ></textarea>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.observaciones}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                            </Form>

                        </div>
                        <div className="modal-footer">
                            <Button type="button" data-bs-dismiss="modal" className='btn-white'>Cerrar</Button>
                            <Button type="button" onClick={handleSubmit} disabled={errors.fecha_hora_llegada}>Actualizar estatus</Button>
                        </div>
                    </>
                )}
            </Formik>


        </>
    );
}

export default OrderStatusLlegada;