import React, { useEffect, useState, useRef } from 'react';
import axiosClient from '../../../config/axios';
import { Link, useNavigate } from 'react-router-dom';
import OrderTable from './ordersTable';
import Column from 'react-bootstrap/Col';
import ReactDOM from 'react-dom';
import Appointment from './appointment';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';
import Button from 'react-bootstrap/Button';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { quickOrderInitialValues, quickOrderValidationSchema } from './orderFieldsYupValidation.js';
import { Store } from 'react-notifications-component';
import Select from 'react-select';
import Swal from 'sweetalert2';
import 'moment/locale/es';
import moment from 'moment';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { QuickOrderToPrint } from './orderPrint/QuickOrderToPrint.js';
import { useReactToPrint } from 'react-to-print';
function Orders() {
	const calendarRef = useRef(null);
	const [orderStatuses, setOrderStatuses] = useState([]);
	const [currentStatus, setCurrentStatus] = useState('TODAS');

	const [updateFlag, setUpdateFlag] = useState(0);
	const [loading, setLoading] = useState(true);
	const [gruas, setGruas] = useState([]);
	const [operadores, setOperadores] = useState([]);
	const navigate = useNavigate();
	const fetchOrderStatuses = async () => {

		try {
			try {
				const response = await axiosClient.get('/orderStatuses/allWithOrderCount', {
					params: {
						sortColumn: 'id'
					},
				});
				setOrderStatuses(response.data.data);
				setLoading(false);
			} catch (error) {
				console.error(error);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		fetchOrderStatuses();

		axiosClient.get('/cranes/all').then(response => {
            const formattedOptions = response.data.data.map(item => {
                const etiqueta = "#" + item.crane_number + ', ' + item.description + ' (' + item.CraneTypes.map(craneType => craneType.crane_type_name).join(', ') + ')';
                return {
                    value: item.id,
                    label: etiqueta,
                    crane_type: item.CraneTypes.map(craneType => craneType.crane_type_name)
                };
            });

            setGruas(formattedOptions);
        }).catch(error => {
            console.error('Error fetching cranes:', error);
        });


		axiosClient.get('/users/allOperators').then(response => {
            const formattedOptions = response.data.data
            .filter(operator => operator.desactivation_date === null)
            .map(operator => ({
                value: operator.id,
                label: operator.name + ' ' + operator.first_surname + ' ' + operator.second_surname,
                actividad: '',
                encargado: ''
            }));
            setOperadores(formattedOptions);
        }).catch(error => {
            console.error('Error fetching allOperators:', error);
        });
	}, []);

	useEffect(() => {
		if (currentStatus === 'CITAS') {
			// Forzar redimensionamiento cuando el tab se muestra
			const calendarApi = calendarRef.current.getApi();
			calendarApi.updateSize();
		}
	}, [currentStatus]);




	const modalRefOrder = useRef();

	const handleOrder = () => {
		const modal = modalRefOrder.current;
		if (modal) {
			const modalInstance = new bootstrap.Modal(modal);
			//setModalInstance(modalInstance);
			modalInstance.show();
		}
	}
	const handleCloseOrder = (resetForm) => {
		const modal = modalRefOrder.current;
		if (modal) {
			const modalInstance = bootstrap.Modal.getInstance(modal);
			if (modalInstance) {
				modalInstance.hide();
			}
		}
		if (resetForm) resetForm();
	};


	function addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition) {

        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: "top",
            animationIn: ["animate__animated", "animate__bounceInRight"],
            container: notificationPosition,
            animationOut: ["animate__animated", "animate__bounceOut"],
            dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true,
                waitForAnimation: true,
                showIcon: true
            },
        });
    }

	const printRef = useRef();
	let [data, setData]=useState([])
	const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });
	/*const handlePrint = () => {
		const printContent = modalRefOrder.current;
	
		const inputs = printContent.querySelectorAll("input, select");
		let formData = {};
	
		inputs.forEach(input => {
			const name = input.getAttribute("name") || input.id || "campo"; 
			const value = input.tagName === "SELECT"
				? input.options[input.selectedIndex]?.text || ""
				: input.value;
			formData[name] = value;
		});
	
		formData.operador = operadores.find(operador => operador.value == formData.operador);
		formData.crane = gruas.find(grua => grua.value == formData.crane);
	
		console.log('los datos de form data', formData);

		let fecha = format(new Date(), 'dd/MMM/yyyy', { locale: es } )
		console.log('la fecha es ', fecha)
		let hora = format(new Date(), 'hh:mm a', { locale: es } )
		console.log('la gora es ', hora)

		const printHTML = `
			<html>
				<head>
					<title>Orden de Servicio</title>
					<style>
						* {
							font-family: Arial, sans-serif;
							margin: 0;
							padding: 0;
							box-sizing: border-box;
						}
						body {
							background-color: #000;
							color: #fff;
							padding: 20px;
						}
						.container {
							width: 100%;
							margin: 0 auto;
							padding: 20px;
							background-color: #fff;
							color: #000;
							border: 2px solid #000;
							text-align: center;
						}
						.header {
							text-align: left;
							padding: 10px;
							display:flex;
						}
						.header img {
							width: 150px;
						}
						.title {
							font-size: 12pt;
							font-weight: bold;
						}
						.info {
							display: flex;
							justify-content: space-between;
							margin: 10px 0;
							padding: 5px;
							font-size: 16px;
						}
						.firma {
							margin-top: 30px;
							text-align: center;
							padding-top: 20px;
						}
						.firma div {
							margin-top: 40px;
							border-top: 1px solid #000;
							width: 50%;
							margin-left: auto;
							margin-right: auto;
						}
						@media print {
							.print-button {
								display: none;
							}
						}
					</style>
				</head>
				<body>
					<div class="container">
						<div class="header row">
							<img src="/assets/img/varo/logo.png" alt="Logo">
							<div class="text-center" style="text-align: center; width: 300px;">
								<p class="title">CARTA DE SERVICIO/C. PORTE</p>
								<p> <span style="color: red; font-weight: bold;"> FOLIO: No. ${formData["folio"]?.toString()?.padStart(5, '0') || "00000"}</span></p>
							</div>
							
							<div class="text-center" style="text-align: center; font-size: 10px; background-color: #ccc;">
								<p>ORDEN REGISTRADA EL</p>
								<p> <span >${fecha + ' ' + hora}</span></p>
							</div>
						</div>
						<p style="font-size:10px;text-align: left;">
							<small >
								R.F.C. VABC-620921-AI9 BLvd. Eduardo Vasconcelos 207-D, Col. Jalatlaco, Oaxaca, Oax.
								<br>C.P. 68080 Tel./Fax. (951) 515-05-95 E-mail: gruasvaro@prodigy.net.mx
							</small>
						</p>
						<div class="info">Operador: ${formData["operador"]?.label || "No especificado"}</div>
						<div class="info">Grúa No.: ${formData["crane"]?.label || "No especificado"}</div>
						<div class="info">Precio: $ ${formData["precio"] || "$0.00"}</div>
						<div class="info">Contado: ${formData["contado"] ? "Sí" : "No"}</div>
						<div class="firma">
							<div>Firma del Cliente</div>
						</div>
					</div>
				</body>
			</html>
		`;
	
		// Abrir nueva ventana para impresión
		const printWindow = window.open('', '', 'width=800,height=600');
		printWindow.document.write(printHTML); 
		printWindow.document.close();
	
		// Esperar a que la ventana cargue y luego imprimir automáticamente
		printWindow.onload = () => {
			printWindow.print();
			printWindow.onafterprint = () => printWindow.close();
		};
	};*/
	
	
	
	useEffect(() => {
		if (data?.folio) {
			handlePrint(); // Se ejecuta solo cuando data se actualiza con un folio
		}
	}, [data]);
	
	
	
	
	
	
	
	
	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
						<li className="breadcrumb-item active">Cartas de Servicio</li>
					</ol>
					<h1 className="page-header mb-0">Cartas de Servicio</h1>
				</div>
				<div className="ms-auto">
					<Link to={'/orders/newOrder'} className="btn btn-success btn-rounded px-4 rounded-pill"><i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Nueva Carta de Servicio</Link>
					<Link onClick={() => {  handleOrder(); }} className="btn btn-success btn-rounded px-4 rounded-pill"><i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Carta de servicio rapida</Link>
				</div>
			</div>


			<div className="row mb-3">
				<div className="col-xl-12">
					<div className="card border-0">
						<ul className="nav nav-tabs nav-tabs-v2 px-3">
							<li className="nav-item me-2" onClick={() => setCurrentStatus('CITAS')}>
								<a href='#tab_appointment' data-bs-toggle="tab" className={`nav-link px-2`}>
									<span className='me-2'>Citas</span>
								</a>
							</li>
							<li className="nav-item me-2" onClick={() => setCurrentStatus('TODAS')}>
								<a href='#tab_all' data-bs-toggle="tab" className={`nav-link px-2 ${currentStatus === 'TODAS' ? 'active' : ''}`}>
									<span className='me-2'>Todas</span>
								</a>
							</li>
							{orderStatuses && orderStatuses.map((orderStatus, index) => (
								<li key={`nav_link_${orderStatus.id}`} className="nav-item me-2" onClick={() => setCurrentStatus(orderStatus.order_status_code)}>
									<a href={`#tab_${orderStatus.id}`} data-bs-toggle="tab" className={`nav-link px-2 ${orderStatus.order_status_code === currentStatus ? 'active' : ''}`}>
										{orderStatus.count_orders !== '0' ? (<span className={`badge bg-${orderStatus.color} rounded-pill me-1`}>{orderStatus.count_orders}</span>) : <span className=''></span>}
										<span className='me-2'>{orderStatus.order_status}</span>
									</a>
								</li>
							))}
						</ul>
						<div className="tab-content p-3">
							<div key='tab_pane_appointment' className='tab-pane fade' id='tab_appointment'>
								<Appointment
									calendarRef={calendarRef}
								/>
							</div>
							<div key='tab_pane_all' className={`tab-pane fade ${currentStatus === 'TODAS' ? 'active show' : ''}`} id='tab_all'>
								{
									currentStatus === 'TODAS' ?
										<OrderTable
											key={`OrderTable_all_${updateFlag}`}
											orderStatusCode={null}
											updateFlag={updateFlag}
										/>
										:
										null
								}
							</div>
							{orderStatuses && orderStatuses.map((orderStatus, index) => (
								<div key={`tab_pane_${orderStatus.id}`} className={`tab-pane fade ${orderStatus.order_status_code === currentStatus ? 'active show' : ''}`} id={`tab_${orderStatus.id}`} >
									{
										orderStatus.order_status_code === currentStatus ?
											<OrderTable
												key={`OrderTable_${orderStatus.id}`}
												orderStatusCode={orderStatus.order_status_code}
											/>
											: null
									}

								</div>
							))}
						</div>

					</div>

				</div>

			</div>



			<div className="modal fade " ref={modalRefOrder}>

                <div className="modal-dialog modal-print">
                    <div className="modal-content ">

                        <Panel>
                            <PanelBody>
                                <Row>

                                    <Col md="11">
										<h2 >Orden Rapida </h2>
                                    </Col>
									<Col md="1">
									<button className=' btn btn-danger' onClick={()=>handleCloseOrder()}>X</button>
                                    </Col>

                                </Row>

								<Row>
								<Formik
                        validationSchema={quickOrderValidationSchema}
                        initialValues={quickOrderInitialValues}
                        validateOnChange
                        onSubmit={({ operador, ...values }, { setSubmitting, resetForm }) => {
							let operadoresValues = []	
							operadoresValues.push(operador)
							let dataQuickOrder= {
								folio:values.folio, crane_id: values.crane,quick_order_price: values.precio,
								subtotal:0,
								fecha_hora_cita: moment().format(),
                                fecha_hora_alerta_cita: moment().format(),
                                fecha_hora_contacto: moment().format(),
                                fecha_hora_entrega: moment().format(),
                                fecha_hora_reporte: moment().format(),
								operadores: operadoresValues,
								tipo_pago_id: values.contado? {value:1} : {value:2},
								base_id: {value:1},
								is_quick_order:true,
								...values

							}
							//handlePrint()
							setUpdateFlag(prev => prev + 1);
							
                            axiosClient.post('/orders/save', dataQuickOrder).then(response => {
                                Swal.fire({
                                    title: 'Registro exitoso.',
                                    text: `Se ha registrado la nueva Carta de Servicio con folio ${String(response.data.folio).padStart(5, '0')}`,
                                    icon: 'success',
                                    confirmButtonText: 'Cerrar',
                                    cancelButtonText: 'Cancelar',
                                    showCancelButton: false,
                                });
								let dataOrderQuick = { ...dataQuickOrder, folio: response.data.folio, quick_order_price:values.precio, precio:values.precio };
    							setData(dataOrderQuick);
								//handlePrint()
                                navigate('/orders');
								setCurrentStatus('TODAS')
								fetchOrderStatuses()
								handleCloseOrder(resetForm)
                            }).catch(err => {
                                console.log(err.response.data);

                                Swal.fire({
                                    title: 'Ups!',
                                    text: 'Ha ocurrido un error',
                                    icon: 'error',
                                    confirmButtonText: 'Cerrar',
                                    cancelButtonText: 'Cancelar',
                                    showCancelButton: false,
                                });

                                setSubmitting(false);
                            });

                        }}

                    >
						{({ handleSubmit, handleChange, handleBlur, validateForm, values, touched, errors, setFieldValue, setFieldTouched, isSubmitting, setSubmitting }) => (
                            <Form noValidate onSubmit={handleSubmit} encType="multipart/form-data" >
								{/*<Form.Group as={Column} md="12" className="mb-3 mt-3">
									<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
										<Form.Label style={{ marginBottom: '0', whiteSpace: 'nowrap', width:'85px' }}>No. Folio</Form.Label>
										<Form.Control
											type="text"
											name="folio"
											placeholder="Número de folio"
											value={values?.folio}
											onChange={handleChange}
											onBlur={handleBlur}
											isInvalid={!!errors.folio}
											autoComplete="off"
											style={{ flexGrow: 1 }}
										/>
									</div>
									<Form.Control.Feedback type="invalid" style={{ display: 'block', marginTop: '5px', textAlign:'center' }}>
										{errors.folio}
									</Form.Control.Feedback>
								</Form.Group>*/}
								<Form.Group as={Column} md="12" className="mt-3">
									<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
										<Form.Label style={{ whiteSpace: 'nowrap', marginBottom: '0', width:'80px' }}>
											{'Chofer'}
										</Form.Label>
										<div style={{ flexGrow: 1 }}>
											<Select
												name="operador"
												options={operadores}
												className={errors.operador ? 'is-invalid' : ''}
												//isInvalid={!!errors.operador}
												placeholder="Selecciona una grúa"
												getOptionLabel={(option) => option?.label}
												getOptionValue={(option) => option?.value}
												isClearable={true}
												isSearchable={true}
												value={values?.operador}
												onChange={(e) => {
													setFieldTouched("operador", true);
													setFieldValue("operador", e);
												}}
												styles={{
													control: (base, state) => ({
														...base,
														border: errors.operador
															? "1px solid red" // Si hay error en crane, borde rojo
															: state.isFocused
															? "1px solid blue" // Si está enfocado, borde azul
															: "1px solid #ccc", // Si no hay error ni enfoque, borde gris
														boxShadow: errors.operador
															? "0 0 5px red" // Resaltar en rojo si hay error
															: state.isFocused
															? "0 0 5px blue" // Resaltar en azul si está enfocado
															: "none",
														"&:hover": {
															border: errors.operador ? "1px solid red" : "1px solid #aaa"
														}
													})
												}}
											/>
										</div>
									</div>
									<Form.Control.Feedback type="invalid" style={{ display: 'block', marginTop: '5px', textAlign:'start', marginLeft:'10%' }}>
										{errors.operador}
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group as={Column} md="12" className="mt-3">
									<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
										<Form.Label style={{ whiteSpace: 'nowrap', marginBottom: '0', width:'80px' }}>
											{'Grúa'}
										</Form.Label>
										<div style={{ flexGrow: 1 }}>
											<Select
												name="crane"
												options={gruas}
												placeholder="Selecciona una grúa"
												getOptionLabel={(option) => option?.label}
												getOptionValue={(option) => option?.value}
												isClearable={true}
												isSearchable={true}
												value={values?.crane}
												onChange={(e) => {
													setFieldTouched("crane", true);
													setFieldValue("crane", e);
												}}
												styles={{
													control: (base, state) => ({
														...base,
														border: errors.crane
															? "1px solid red" // Si hay error en crane, borde rojo
															: state.isFocused
															? "1px solid blue" // Si está enfocado, borde azul
															: "1px solid #ccc", // Si no hay error ni enfoque, borde gris
														boxShadow: errors.crane
															? "0 0 5px red" // Resaltar en rojo si hay error
															: state.isFocused
															? "0 0 5px blue" // Resaltar en azul si está enfocado
															: "none",
														"&:hover": {
															border: errors.crane ? "1px solid red" : "1px solid #aaa"
														}
													})
												}}
											/>
										</div>
									</div>
									<Form.Control.Feedback type="invalid" style={{ display: 'block', marginTop: '5px', textAlign:'start', marginLeft:'10%' }}>
										{errors.crane}
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group as={Column} md="12" className="mt-3">
									<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
										<Form.Label style={{ whiteSpace: 'nowrap', marginBottom: '0', width:'85px' }}>Precio</Form.Label>
										<Form.Control
											type="number"
											name="precio"
											placeholder="Precio"
											value={values.precio}
											onChange={handleChange}
											onBlur={handleBlur}
											isInvalid={!!errors.precio}
											autoComplete="off"
											style={{ flexGrow: 1 }}
											min={'0'}
										/>
									</div>
									<Form.Control.Feedback type="invalid" style={{ display: 'block', marginTop: '5px', textAlign:'start', marginLeft:'10%' }}>
										{errors.precio}
									</Form.Control.Feedback>
								</Form.Group>
									<Form.Group as={Col} className="mt-3 ms-3"  >
                                    	<Form.Check
                                        	name="contado"
                                        	type="switch"
                                        	label="Contado"
                                        	checked={values.contado}
                                        	onChange={(e) => {
                                            	setFieldTouched("contado", true);
                                            	setFieldValue("contado", e.target.checked);
                                        	}}
                                        	onBlur={handleBlur}
                                    	/>
                                	</Form.Group>

									{/*<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', marginTop: '20%' }}>
										<hr style={{ width: '30%' }} />
										<div>Firma del cliente</div>
									</div>*/}

									<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
										<Button
											type="button"
											data-tooltip-id="tooltip_cancel_button"
											onClick={() => handleCloseOrder()}
											style={{ pointerEvents: 'auto', background:'red' }}
										>
											Cerrar
										</Button>
										<Button
											type="submit"
											//disabled={isSubmitting}
											data-tooltip-id="tooltip_submit_button"
											style={{ pointerEvents: 'auto' }}
										>
											Guardar e imprimir
										</Button>
									</div>
									<div style={{ display: "none" }}>
										<QuickOrderToPrint ref={printRef} orderData={data} folio={data?.folio} />
									</div>
									
							</Form>
                        )}
                    </Formik>
								</Row>
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>


		</div >

	);
}

export default Orders;