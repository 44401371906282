import React from "react";
import { utils, writeFile } from "xlsx";
import { useLocation } from "react-router-dom";
import CustomInput from "../components/formik/CustomInput";
import { ServiceConact } from "../components/reports/ServiceColumn";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import {
  getComission,
  getCurrentFortnight,
  getCurrentFortnightName,
  getOrderType,
  getReason,
} from "../models/ReportOperatorSalary";

const OrderInvoiceForm = ({ formik, records }) => {
  const baseUrl = window.location.origin;
  const estatus = (id_estatus)=>{
    const estados = {
      1: "Sin asignar",
      2: "Asignada",
      3: "Enviada",
      4: "Contacto",
      5: "Llegada",
      6: "Pagado (contado)",
      7: "Entrega",
      8: "Evaluado",
      9: "Pagado (crédito)",
      10: "Cierre",
      11: "Cancelado",
      12: "Factura"
    };
  
    return estados[id_estatus] || "Desconocido";
}
const exportToExcel = () => {
  const workbook = utils.book_new();

  // Definir encabezados
  const sheetData = [
    ["Folio", "Descripción del servicio", "Tipo de Servicio", "Fecha", "Total (Sin IVA)", "Tipo de orden", "Estatus de la orden"],
  ];

  let totalSubtotal = 0;
  let totalComission = 0;
  let totalDistanceSum = 0;

  // Recorrer todos los operadores y sus órdenes
  records.forEach((Order) => {
        if (
          new Date(Order.fecha_hora_reporte) >= getCurrentFortnight().start &&
          new Date(Order.fecha_hora_reporte) <= getCurrentFortnight().end
        ) {
          sheetData.push([
            {
              t: "s",
              v: Order.folio.toString().padStart(5, "0"),
              l: { Target: `${baseUrl}/orders/edit/${Order.id}` },
            },
            getOrderType(Order),
            ServiceConact({ row: Order }),
            format(new Date(Order.fecha_hora_reporte), "dd/MMM/yyyy", {
              locale: es,
            }),
            { t: "n", v: Order.subtotal },
            Order.is_quick_order? 'Rapida' : 'Oficina',
            estatus(Order.current_order_status_id)

          ]);

          totalSubtotal += Order.subtotal;
        }
      });

  // Agregar fila vacía antes del total
  sheetData.push([{ t: "s", v: "" }]);

  // Agregar fila de totales
  sheetData.push([
    { t: "s", v: "Total" },
    { t: "s", v: "" },
    { t: "s", v: "" },
    { t: "s", v: "" },
    { t: "n", v: totalSubtotal },
    { t: "s", v: "" },
  ]);

  // Crear hoja con todos los datos
  const worksheet = utils.aoa_to_sheet(sheetData);

  // Ajustar anchos de columna
  worksheet["!cols"] = [
    { wch: 20 },
    { wch: 35 },
    { wch: 20 },
    { wch: 30 },
    { wch: 20 },
    { wch: 20 },
  ];

  // Agregar hoja al libro con nombre "Órdenes Facturables"
  utils.book_append_sheet(workbook, worksheet, "Órdenes Facturables");

  // Guardar el archivo
  writeFile(workbook, getCurrentFortnightName() + ".xlsx");
};


  return (
    <React.Fragment>
      <div className="row">
        <div className="row">
          <CustomInput
            label="Fecha inicio"
            field="start"
            type="date"
            formik={formik}
            sm={2}
          />
          <CustomInput
            label="Fecha fin"
            field="end"
            type="date"
            formik={formik}
            sm={2}
          />


          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div
              style={{
                display: "flex",
                gap: 10,
                justifyContent: "end",
                marginTop: 20,
              }}
            >
              <button
                className="btn btn-primary btn-lg"
                onClick={() => formik.handleSubmit()}
              >
                <i class="fa-solid fa-magnifying-glass" />
                &nbsp;Buscar
              </button>
              <button
                className="btn btn-success btn-lg"
                onClick={() => exportToExcel()}
              >
                <i class="fa-solid fa-file-excel" />
                &nbsp;Descargar
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderInvoiceForm;
