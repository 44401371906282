import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { format } from "date-fns";

import { es } from "date-fns/locale";

import ServiceColumn from "./ServiceColumn";
import { formatName, formatNumberWithCommas } from "../../utils";
import { getComission, getCurrentFortnight, getOrderType, getReason } from "../../models/ReportOperatorSalary";

const TableOrderInsurance = ({ orders, start, end }) => {
  
  return (
    <table className="table table-hover table-panel text-nowrap align-middle mb-0">
      <thead className="sticky-thead table-light text-center">
        <tr>
          <th className="align-middle">Orden</th>
          <th className="align-middle">Tipo de Servicio</th>
          <th className="align-middle">Aseguradora</th>
          <th className="align-middle">Descripción del servicio</th>
          <th className="align-middle">Fecha</th>
          <th className="align-middle">Total (Sin IVA)</th>
          {/*<th className="align-middle">KM aproximados</th>
          <th className="align-middle">Operador</th>
          <th className="align-middle">Comisión</th>
          <th className="align-middle">Razón</th>*/}
        </tr>
      </thead>

      <tbody>
        {orders
          .sort(
            (a, b) =>
              new Date(a.fecha_hora_reporte) - new Date(b.fecha_hora_reporte)
          )
          .map((order) => (
            <tr className="text-center">
              <td>
                <Row className="mb-2">
                  <Col md="12">
                    <Link to={`/orders/edit/${order.id}`}>
                      {order.folio.toString().padStart(5, "0")}
                    </Link>
                  </Col>
                </Row>
              </td>
              <td>{getOrderType(order)}</td>
              <td>{order?.insurance?.insurance_name}</td>

              <td>
                <ServiceColumn row={order} />
              </td>
              <td>
                {format(new Date(order.fecha_hora_reporte), "dd/MMM/yyyy", {
                  locale: es,
                })}
              </td>
              <td>${formatNumberWithCommas(order.subtotal) || '0.00'}</td>
              {/*<td>{Number(order.totalDistance).toFixed(2)} km</td>
              <td>{formatName(order.operador)}</td>
              <td>${formatNumberWithCommas(getComission(order))}</td>
              <td>{getReason(order)}</td>*/}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default TableOrderInsurance;
