import React from "react";
import { getIn, setIn } from "formik";

const CustomSelect = ({
  sm = 12,
  required = false,
  unclickable = false,
  options = [],
  onChange = () => {},
  ...props
}) => {
  const error =
    getIn(props.formik.touched, props.field) &&
    getIn(props.formik.errors, props.field);

  return (
    <div className={`col-md-${sm}`}>
      <label
        className={`form-label`}
        style={{ color: error ? "red" : "inherit" }}
      >
        {props.label}&nbsp;
        {required && (
          <span style={{ color: error ? "red" : "inherit" }}>*</span>
        )}
      </label>
      <div className={`row fs-13px col-md-12`}>
        <div className={`col-md-12`} style={unclickable ? { cursor: "not-allowed" } : {}}>
          <select
            className={`form-control mb-5px fs-13px ${error ? "is-invalid" : ""}`}
            id={props.field}
            name={props.field}
            onChange={(event) => {
              onChange();
              const updatedValues = setIn(
                props.formik.values,
                props.field,
                event.target.value
              );
              props.formik.setValues(updatedValues);
            }}
            value={getIn(props.formik.values, props.field) || ""}
            disabled={props.disabled || unclickable}
          >
            <option value="" disabled>
              {props.placeholder || "Seleccione una opción"}
            </option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div className="invalid-feedback" style={{ marginTop: "1px" }}>
            {error ? <div>{getIn(props.formik.errors, props.field)}</div> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;
