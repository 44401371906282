import React from "react";
import { getIn, setIn } from "formik";

const CustomInput = ({
  sm = 12,
  smLabel = 3,
  min = 0,
  max = 1000000,
  hidden = false,
  required = false,
  readonly = false,
  withIcon = false,
  autoFocus = false,
  type = "text",
  unclickable = false,
  onChange = () => {},
  onChangeFile = (e) => {},
  accept = "",
  classInput = "",
  currency = false,
  ...props
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const error =
    getIn(props.formik.touched, props.field) &&
    getIn(props.formik.errors, props.field);
  return (
    <div className={`col-md-${sm}`}>
      <label
        className={`form-label`}
        style={{ color: error ? "red" : "inherit" }}
      >
        {props.label}&nbsp;
        {required && (
          <span style={{ top: 0, right: 0, color: error ? "red" : "inherit" }}>
            *
          </span>
        )}
      </label>
      <div className={`row fs-13px col-md-12 ${classInput}`}>
        <div
          className={`col-md-12`}
          style={unclickable ? { cursor: "not-allowed" } : {}}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              type={type === "password" && showPassword ? "text" : type}
              className={`form-control mb-5px fs-13px ${
                error ? "is-invalid" : ""
              }`}
              id={props.field}
              name={props.field}
              placeholder={props.placeholder || props.label}
              onChange={(event) => {
                onChange();

                if (type === "number") {
                  const parsed = parseInt(event.target.value);
                  const finalParsed = isNaN(parsed) ? 0 : parsed;

                  if (finalParsed < min) {
                    event.target.value = "0";
                  } else if (finalParsed > max) {
                    event.target.value = String(max);
                  }
                }

                // props.formik.handleChange(event);
                const updatedValues = setIn(
                  props.formik.values,
                  props.field,
                  event.target.value
                );
                props.formik.setValues(updatedValues);

                if (type === "file") {
                  const file = event?.target?.files[0];
                  onChangeFile(file);
                }
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  props.formik.handleSubmit();
                }
              }}
              // value={props.formik.values[props.field]}
              value={
                getIn(props.formik.values, props.field)
              }
              readOnly={readonly}
              disabled={props.disabled}
              style={unclickable ? { pointerEvents: "none" } : {}}
              accept={accept}
            />
            {type === "password" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginLeft: "0.5em",
                }}
              >
                <i
                  className={`fa fa-eye ${showPassword ? "text-primary" : ""}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            )}
          </div>
          <div
            className="invalid-feedback"
            style={{ marginTop: "1px", display: "flex", marginLeft: "0.25em" }}
          >
            {error ? (
              <div>{getIn(props.formik.errors, props.field)}</div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomInput;