import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import DateTime from 'react-datetime';
import axiosClient from '../../../../config/axios.js';


function FieldsIncidente({ values, errors, touched, setFieldValue, setFieldTouched }) {

    const [usuariosIncidente, setUsuariosIncidente] = useState([]);
    const [tiposIncidente, setTiposIncidente] = useState([]);
    const fechaHoraIncidente = useRef(null);

    useEffect(() => {
        axiosClient.get('/users/allUsers').then(response => {
            
            const formattedOptions = response.data.data.map(user => ({
                value: user.id,
                label: user.name + ' ' + user.first_surname + ' ' + user.second_surname,
            }));
            setUsuariosIncidente(formattedOptions);
        }).catch(error => {
            console.error('Error fetching allUsers:', error);
        });

        axiosClient.get('/incidentTypes/all').then(response => {
            
            const formattedOptions = response.data.data.map(incidentType => ({
                value: incidentType.id,
                label: incidentType.incident_type
            }));
            setTiposIncidente(formattedOptions);
        }).catch(error => {
            console.error('Error fetching Type Evidences:', error);
        });
    }, []);

    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Fecha y hora del incidente</Form.Label>
                    <DateTime
                        name="incident_datetime"
                        ref={fechaHoraIncidente}
                        inputProps={{ placeholder: 'Fecha y hora del incidente', readOnly: true }}
                        closeOnSelect={false}
                        locale="es"
                        value={values.incident_datetime}
                        className={errors.incident_datetime ? 'is-invalid' : ''}
                        dateFormat="dddd DD/MMMM/YYYY"
                        timeFormat="hh:mm A"
                        initialViewMode="days"
                        onOpen={(e) => {
                            fechaHoraIncidente.current.navigate('days');
                            setFieldTouched('incident_datetime', true);
                        }}
                        onChange={(e) => {
                            setFieldValue("incident_datetime", e ? e : '');
                        }}
                        isInvalid={touched.incident_datetime && !!errors.incident_datetime}
                        // updateOnView="time"
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.incident_datetime}
                    </Form.Control.Feedback>


                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="12">
                    <Form.Label>Tipo de incidente</Form.Label>
                    <Select
                        name="incident_types"
                        options={tiposIncidente}
                        className={errors.incident_types ? 'is-invalid' : ''}
                        placeholder="Selecciona uno o más tipos de incidentes"
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        isClearable={true}
                        isSearchable={true}
                        value={values.incident_types}
                        isMulti
                        onChange={(e) => {
                            setFieldValue("incident_types", e ? e : '');
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.incident_types}
                    </Form.Control.Feedback>

                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} md="12">
                    <Form.Label>Descripción del incidente</Form.Label>
                    <textarea
                        name="incident_description"
                        className={`form-control ${errors.incident_description ? 'is-invalid' : ''}`}
                        onChange={(e) => {
                            setFieldValue('incident_description', e.target.value);
                        }}
                        value={values.incident_description}
                    ></textarea>

                    <Form.Control.Feedback type="invalid">
                        {errors.incident_description}
                    </Form.Control.Feedback>

                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} md="12">
                    <Form.Label>Solución al incidente</Form.Label>
                    <textarea
                        name="resolution_actions"
                        className={`form-control ${errors.resolution_actions ? 'is-invalid' : ''}`}
                        onChange={(e) => {
                            setFieldValue('resolution_actions', e.target.value);
                        }}
                        value={values.resolution_actions}
                    ></textarea>

                    <Form.Control.Feedback type="invalid">
                        {errors.resolution_actions}
                    </Form.Control.Feedback>

                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} md="12">
                    <Form.Label>Usuario o usuarios implicados en el incidente</Form.Label>
                    <Select
                        name="incident_users"
                        options={usuariosIncidente}
                        className={errors.incident_users ? 'is-invalid' : ''}
                        placeholder="Selecciona uno o más usuarios"
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        isClearable={true}
                        isSearchable={true}
                        value={values.incident_users}
                        isMulti
                        onChange={(e) => {
                            setFieldValue("incident_users", e ? e : '');
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.incident_users}
                    </Form.Control.Feedback>

                </Form.Group>
            </Row>

        </>
    );
}

export default FieldsIncidente;