import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import { useFormikContext, Formik, Form, Field } from "formik";
import Swal from "sweetalert2";
import moment from "moment";
import "moment-duration-format";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.js";
import axiosClient from "../../../config/axios.js";
import OrderFields from "./orderFields";
import {
  orderValidationSchema,
  orderInitialValues,
  opcionesEsCita,
} from "./orderFieldsYupValidation";
import OrderStatus from "./orderStatus/orderStatus.js";
import OrderStatusCancelar from "./orderStatus/orderStatusCancelar.js";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";

import { useReactToPrint } from "react-to-print";

import { ComponentToPrint } from "./orderPrint/ComponentToPrint.js";

import { Col } from "react-bootstrap";
import { Panel, PanelBody } from "../../../components/panel/panel.jsx";
import OrderStatusFinalizarCancelacion from "./orderStatus/orderStatusFinalizarCancelacion.js";
import { useAuth } from "../../../context/AuthContext.js";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import notFund from './orderAmounts/notFund.svg'

function TimelineOrder() {
  const { id } = useParams();
  const { userId, fullName } = useAuth();
  const navigate = useNavigate();

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("id"); // Campo de ordenamiento predeterminado
  const [sortDirection, setSortDirection] = useState("desc"); // Sentido de orden predeterminado
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  //console.log("los datos de data es ", data);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    axiosClient
      .get(`/order-service/${id}/history`)
      .then((response) => {
        setData(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching allOperators:", error);
      });
  }, [id]);


  const columns = [
    {
      name: "Campo Cambiado",
      selector: (row, i) => nombresCampos(row?.field_changed),
      sortable: true,
      width: "30%",
      cell: (row) => (
          <div style={colores(row?.field_changed)}>
              {nombresCampos(row?.field_changed)}
          </div>
      ),
  },
    {
      name: "Valor anterior",
      selector: (row, i) => row?.old_value,
      sortable: true,
      width: "35%",
    },
    {
      name: "Nuevo valor",
      selector: (row, i) => row?.new_value,
      sortable: true,
      width: "35%",
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
        backgroundColor: "#EEEEEE",
        border: "none",
      },
    },
  };

  const formatearFecha = (fecha) => {
    const fechaFormateada = format(new Date(fecha), "d 'de' MMMM 'del' yyyy", {
      locale: es,
    });
    return fechaFormateada;
  };
  const formatearHora = (fecha) => {
    const horaFormateada = format(new Date(fecha), "hh:mm a");
    return horaFormateada;
  };

  const colores = (campo_name) => {
    const colorMapping = {
        'crane_id': 'yellow',
        'current_order_status_id': 'primary',
        'total': 'red',
        'subtotal': 'orange',
    };

    return { backgroundColor: colorMapping[campo_name] || '' };
};
  const nombresCampos = (campo_name) => {
    const campos = {
        'alerta_antes_hora_cita': 'Alerta Antes Hora Cita',
        'autorizacion_cabina': 'Autorización Cabina',
        'aux_vial_banderazo': 'Aux Vial Banderazo',
        'aux_vial_casetas': 'Aux Vial Casetas',
        'aux_vial_subtotal': 'Aux Vial Subtotal',
        'aux_vial_tarifa_kilometro': 'Aux Vial Tarifa Kilómetro',
        'auxilio_vial': 'Auxilio Vial',
        'base_id': 'Base ID',
        'beneficiario_servicio': 'Beneficiario Servicio',
        'bienes_observaciones': 'Bienes Observaciones',
        'carta_porte': 'Carta Porte',
        'client_type_id': 'Tipo de Cliente',
        'cliente_vip': 'Cliente VIP',
        'contadoTotalInvoice': 'Contado Total Factura',
        'contadoTotalPayed': 'Contado Total Pagado',
        'corresponsalia': 'Corresponsalía',
        'corresponsalia_observaciones': 'Corresponsalía Observaciones',
        'corresponsalia_subtotal': 'Corresponsalía Subtotal',
        'crane_id': 'Grua ID',
        'creditoTotalInvoice': 'Crédito Total Factura',
        'creditoTotalPayed': 'Crédito Total Pagado',
        'current_order_status_id': 'Estado Actual de la Orden',
        'datos_contacto_seguimiento_observaciones': 'Datos Contacto Seguimiento Observaciones',
        'edit_locations_finished': 'Ubicaciones Editadas Completadas',
        'empresa': 'Empresa',
        'es_cita': 'Es Cita',
        'excedente_asegurado': 'Excedente Asegurado',
        'expediente': 'Expediente',
        'fecha_hora_alerta_cita': 'Fecha Hora Alerta Cita',
        'fecha_hora_cita': 'Fecha Hora Cita',
        'fecha_hora_contacto': 'Fecha Hora Contacto',
        'fecha_hora_entrega': 'Fecha Hora Entrega',
        'fecha_hora_envio': 'Fecha Hora Envío',
        'fecha_hora_llegada': 'Fecha Hora Llegada',
        'fecha_hora_reporte': 'Fecha Hora Reporte',
        'fecha_hora_termino': 'Fecha Hora Término',
        'folio': 'Folio',
        'globalTotalInvoice': 'Total Global Factura',
        'globalTotalPayed': 'Total Global Pagado',
        'grua': 'Grua',
        'grua_banderazo': 'Grua Banderazo',
        'grua_casetas': 'Grua Casetas',
        'grua_subtotal': 'Grua Subtotal',
        'grua_tarifa_kilometro': 'Grua Tarifa Kilómetro',
        'grua_vial': 'Grua Vial',
        'id': 'ID',
        'insurance_id': 'ID de Seguro',
        'invoices_contado': 'Facturas Contado',
        'invoices_credito': 'Facturas Crédito',
        'is_quick_order': 'Es Orden Rápida',
        'iva': 'IVA',
        'km': 'Kilómetros',
        'manejar_precios_netos': 'Manejar Precios Netos',
        'manejar_tarifas_establecidas': 'Manejar Tarifas Establecidas',
        'montacarga': 'Montacarga',
        'montacargas': 'Montacargas',
        'montacargas_costo_hora': 'Costo Hora Montacargas',
        'montacargas_horas': 'Horas Montacargas',
        'montacargas_subtotal': 'Subtotal Montacargas',
        'montacargas_traslado': 'Traslado Montacargas',
        'monto_aseguradora_paga': 'Monto Aseguradora Paga',
        'monto_blindaje': 'Monto Blindaje',
        'monto_carga_volumen': 'Monto Carga Volumen',
        'montosExtras': 'Montos Extras',
        'need_invoice': 'Necesita Factura',
        'numero_folio': 'Número Folio',
        'numero_poliza': 'Número Póliza',
        'numero_siniestro': 'Número Siniestro',
        'operadores': 'Operadores',
        'orderStatuses': 'Estados de la Orden',
        'order_locations': 'Ubicaciones de la Orden',
        'payments_contado': 'Pagos Contado',
        'payments_credito': 'Pagos Crédito',
        'quick_order_price': 'Precio Orden Rápida',
        'reporte': 'Reporte',
        'service_location_type_id': 'ID Tipo de Ubicación de Servicio',
        'servicio_muerto_monto_penalizacion': 'Monto Penalización Servicio Muerto',
        'someWithCarga': 'Con Carga',
        'someWithVolumen': 'Con Volumen',
        'subtotal': 'Subtotal',
        'taxi': 'Taxi',
        'taxi_subtotal': 'Subtotal Taxi',
        'telefono_principal': 'Teléfono Principal',
        'tipo_pago_id': 'Tipo de Pago',
        'tipo_servicio_observaciones': 'Observaciones Tipo de Servicio',
        'total': 'Total',
        'totalPendingExcedente': 'Total Pendiente Excedente',
        'totalPendingToPay': 'Total Pendiente a Pagar',
        'vehiculos': 'Vehículos',
        'vehiculos_asistencia': 'Vehículos Asistencia',
        'venta_publico': 'Venta Público',
        'updatedAt': 'Fecha actualización',
        "total_distance": 'Distancia Total'
    };

    return campos[campo_name] || '';
}


  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Inicio</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/orders">Órdenes</Link>
            </li>
            <li className="breadcrumb-item active">Historial Orden</li>
          </ol>
          <h1 className="page-header mb-0">Historial Orden</h1>
        </div>
      </div>
      {data?.length === 0 && 
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
          <img src={notFund}  alt="No encontrado" />
          <h1>Sin datos para mostrar</h1>
        </div>
      }


      {data?.map((orden, index) => (
        <div className="timeline" key={index}>
          <div className="timeline-item">
            <div className="timeline-time">
              <span className="date">{formatearFecha(orden.updated_timestamp)}</span>
              <span className="time">{formatearHora(orden.updated_timestamp)}</span>
            </div>
            <div className="timeline-icon">
              <a href="#/">&nbsp;</a>
            </div>
            <div className="timeline-content">
              <div className="timeline-header">
                <div className="userimage">
                  <img src="/assets/img/user/user-1.jpg" alt="" />
                </div>
                <div className="username">
                  <a href="#/">
                    {orden?.records?.[0]?.user.name + ' '+ orden?.records?.[0]?.user.first_surname+ ' '+ orden?.records?.[0]?.user.second_surname}
                    <i className="fa fa-check-circle text-blue ms-1"></i>
                  </a>
                  {/*<div className="text-muted fs-12px">
                    8 mins{" "}
                  </div>*/}
                </div>
              </div>
              <div className="timeline-body">
                <div className="mb-3">
                  <div className="row gx-1">
                    <DataTable
                      title=""
                      className="table"
                      columns={columns}
                      //progressPending={loading}
                      data={orden?.records}
                      sortServer
                      pagination
                      paginationServer
                      subHeaderAlign="center"
                      /*paginationPerPage={rowsPerPage}
                      paginationTotalRows={data.recordsFiltered}
                      paginationDefaultPage={currentPage}
                      subHeader
                      subHeaderWrap={false}
                      subHeaderAlign="center"*/
                      customStyles={customStyles}
                      pointerOnHover
                      /*paginationRowsPerPageOptions={[50, 100, 200, 500]}
                      paginationComponentOptions={{
                        rowsPerPageText: "Filas por página",
                        rangeSeparatorText: "de",
                        selectAllRowsItem: false,
                      }}*/
                      noDataComponent="Sin información por mostrar"
                      progressComponent={
                        <Spinner animation="border" location="status">
                          <span className="visually-hidden">Cargando...</span>
                        </Spinner>
                      }
                    />
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TimelineOrder;
