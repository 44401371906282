import React, { useRef } from 'react';

import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useFormikContext, Formik } from 'formik';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';
import DateTime from 'react-datetime';
import moment from 'moment';

function OrderStatusEntrega({ totalPendingToPay, fecha_hora_llegada, fetchCurrentOrderStatusByOrder, setCurrentOrderStatus, modalInstance, reloadOrderDataFromDB }) {
    const { id } = useParams();
    const { values: orderValues, submitForm } = useFormikContext();

    const fechaHoraEntrega = useRef(null);
    const fechaHoraLlegada = useRef(null);

    //console.log("orderValues entrega", orderValues);
    return (
        <>
            <Formik
                validationSchema={yup.object().shape({
                    fecha_hora_entrega: yup.date().required("La fecha y hora de entrega es requerida.").min(moment(fecha_hora_llegada).add(1, 'second'), 'La fecha y hora de entrega debe ser posterior a la de llegada'),
                    observaciones: yup.string()
                })}
                initialValues={{
                    fecha_hora_entrega: fecha_hora_llegada >= new Date() ? moment(fecha_hora_llegada).add(1, 'minute') : new Date(),
                    observaciones: ''
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    axiosClient.put(`/orders/updateCurrentOrderStatus/${id}`, {
                        order_status_code: 'ENTREGA',
                        fecha_hora_entrega: moment(values.fecha_hora_entrega).format(),
                        observations: values.observaciones
                    }).then(async response => {
                        const result = await fetchCurrentOrderStatusByOrder();
                        setCurrentOrderStatus(result);
                        console.table("setCurrentOrderStatus", result);

                        if (modalInstance) {
                            modalInstance.hide();
                        }
                        reloadOrderDataFromDB();

                    }).catch(err => {
                        console.log(err.response);
                        Swal.fire({
                            title: 'Ups!',
                            text: 'Ha ocurrido un error',
                            icon: 'error',
                            confirmButtonText: 'Cerrar',
                            cancelButtonText: 'Cancelar',
                            showCancelButton: false,
                        });

                    });
                }}

            >

                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (
                    <>
                        <div className="modal-header">
                            <h4 className="modal-title">Registrar Fecha y hora de Entrega</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <div className="modal-body">
                            <Form onSubmit={handleSubmit}>

                                {totalPendingToPay !== 0 &&
                                    (
                                        <Row>
                                            <div className={`note mb-2 ${totalPendingToPay < 0 ? 'alert-warning'
                                                : (
                                                    orderValues?.tipo_pago_id?.code === 'CONTADO' ? (totalPendingToPay > 0 ? 'alert-danger' : 'alert-primary')
                                                        : (
                                                            orderValues?.tipo_pago_id?.code === 'CONTADO/CREDITO' ? (
                                                                orderValues?.totalPendingExcedente > 0 ? 'alert-danger' : 'alert-primary'
                                                            )
                                                                : 'alert-primary'
                                                        )
                                                )
                                                }`}>
                                                <div className="note-icon">
                                                    {
                                                        totalPendingToPay < 0 ? <i className="fa-solid fa-sack-dollar"></i>
                                                            : (
                                                                orderValues?.tipo_pago_id?.code === 'CONTADO' ? (totalPendingToPay > 0 ? <i className="fa-solid fa-face-frown-open"></i> : <i className="fa-solid fa-face-smile-wink"></i>)
                                                                    : (
                                                                        orderValues?.tipo_pago_id?.code === 'CONTADO/CREDITO' ? (
                                                                            orderValues?.totalPendingExcedente > 0 ? <i className="fa-solid fa-face-frown-open"></i> : <i className="fa-solid fa-face-smile-wink"></i>
                                                                        )
                                                                            : <i className="fa-solid fa-face-smile-wink"></i>
                                                                    )
                                                            )
                                                    }

                                                </div>
                                                <div className="note-content">
                                                    <h4><b>
                                                        {
                                                            totalPendingToPay < 0 ? 'Orden con saldo a favor' : (
                                                                orderValues?.tipo_pago_id?.code === 'CONTADO'
                                                                    ?
                                                                    (totalPendingToPay > 0 ? 'Orden con adeudo' : 'Orden sin adeudo')
                                                                    :
                                                                    (orderValues?.tipo_pago_id?.code === 'CONTADO/CREDITO'
                                                                        ? (orderValues?.totalPendingExcedente > 0 ? 'Orden con adeudo Excedente Asegurado' : (totalPendingToPay > 0 ? 'Orden con adeudo' : 'Orden sin adeudo'))
                                                                        : (totalPendingToPay > 0 ? 'Orden con adeudo' : 'Orden sin adeudo')
                                                                    )
                                                            )
                                                        }

                                                    </b></h4>

                                                    <Row>
                                                        <Col sm='12'>

                                                            <Form.Group className="">
                                                                <Form.Label>


                                                                    {
                                                                        totalPendingToPay < 0 ? 'La Orden tiene un saldo a favor de:' : (
                                                                            orderValues?.tipo_pago_id?.code === 'CONTADO'
                                                                                ?
                                                                                (totalPendingToPay > 0 ? 'La Orden presenta un adeudo de:' : 'La Orden no presenta adeudos.')
                                                                                :
                                                                                (orderValues?.tipo_pago_id?.code === 'CONTADO/CREDITO'
                                                                                    ? (orderValues?.totalPendingExcedente > 0 ? 'La Orden presenta un adeudo del Excedente Asegurado por: ' : (totalPendingToPay > 0 ? 'La Orden presenta un adeudo a crédito, que debe cubrirse más adelante, por: ' : 'La Orden no presenta adeudos.'))
                                                                                    : (totalPendingToPay > 0 ? 'La Orden presenta un adeudo a crédito, que debe cubrirse más adelante, por: ' : 'La Orden no presenta adeudos.')
                                                                                )
                                                                        )
                                                                    }



                                                                </Form.Label>
                                                                {(totalPendingToPay && parseFloat(totalPendingToPay) !== 0) && (
                                                                    <>
                                                                        <InputGroup >
                                                                            <InputGroup.Text>$</InputGroup.Text>
                                                                            <CurrencyInput
                                                                                name="totalPendingToPay"
                                                                                autoComplete='off'
                                                                                className={`form-control`}
                                                                                prefix=""
                                                                                allowNegativeValue={false}
                                                                                decimalsLimit={2}
                                                                                decimalScale={2}
                                                                                decimalSeparator="."
                                                                                groupSeparator=","
                                                                                value={orderValues?.tipo_pago_id?.code === 'CONTADO/CREDITO' && orderValues?.totalPendingExcedente > 0 ? orderValues?.totalPendingExcedente : totalPendingToPay}
                                                                                disabled={true}
                                                                            />
                                                                        </InputGroup>
                                                                        {totalPendingToPay > 0 ? 'La Orden podrá seguir actualizándose de estatus, pero no podrá cerrarse hasta que se finiquite o justifique el adeudo.' : (
                                                                            totalPendingToPay < 0 ? 'La Orden presenta saldo a favor. Es posible continuar con la actualización del estatus.' : ''
                                                                        )}
                                                                    </>
                                                                )}




                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                </div>
                                            </div>
                                        </Row>
                                    )
                                }


                                <Row className="mb-4">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Fecha y hora de llegada registrada</Form.Label>
                                        <DateTime
                                            disabled={true}
                                            name="fecha_hora_llegada"
                                            ref={fechaHoraLlegada}
                                            inputProps={{
                                                placeholder: 'Fecha y hora de llegada',
                                                disabled: true
                                            }}
                                            locale="es"
                                            value={fecha_hora_llegada}
                                            dateFormat="dddd DD/MMMM/YYYY"
                                            timeFormat="hh:mm A"
                                            initialViewMode="days"
                                            // updateOnView="time"
                                        />
                                    </Form.Group>
                                </Row>


                                <Row className="mb-1">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Fecha y hora de entrega</Form.Label>
                                        <DateTime
                                            name="fecha_hora_entrega"
                                            ref={fechaHoraEntrega}
                                            inputProps={{ placeholder: 'Fecha y hora de entrega',readOnly: true }}
                                            closeOnSelect={false}
                                            locale="es"
                                            value={values.fecha_hora_entrega}
                                            className={errors.fecha_hora_entrega ? 'is-invalid' : ''}
                                            dateFormat="dddd DD/MMMM/YYYY"
                                            timeFormat="hh:mm A"
                                            initialViewMode="days"
                                            onOpen={(e) => {
                                                fechaHoraEntrega.current.navigate('days');
                                                setFieldTouched('fecha_hora_entrega', true);
                                            }}
                                            onChange={(e) => {
                                                setFieldValue("fecha_hora_entrega", e ? e : '');
                                            }}
                                            isInvalid={touched.fecha_hora_entrega && !!errors.fecha_hora_entrega}
                                            // updateOnView="time"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.fecha_hora_entrega}
                                        </Form.Control.Feedback>


                                    </Form.Group>
                                </Row>

                                <Row className="mb-1">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Observaciones</Form.Label>
                                        <textarea
                                            name="observaciones"
                                            className={`form-control ${errors.observaciones ? 'is-invalid' : ''}`}
                                            onChange={(e) => {
                                                setFieldValue('observaciones', e.target.value);
                                            }}
                                            value={values.observaciones}
                                        ></textarea>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.observaciones}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                            </Form>

                        </div>
                        <div className="modal-footer">
                            <Button type="button" data-bs-dismiss="modal" className='btn-white'>Cerrar</Button>
                            <Button type="button" onClick={handleSubmit} disabled={errors.fecha_hora_entrega}>Actualizar estatus</Button>
                        </div>
                    </>
                )}
            </Formik >


        </>
    );
}

export default OrderStatusEntrega;